.content-modal {
  &_lang {
    display: block;
  }

  .lang {
    margin-bottom: 14px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      font-weight: normal;

      & span {
        display: inline;
        opacity: 0.25;
        margin-left: 8px;
      }
    }

    &__edit {
      width: 16px;
      height: 16px;
      margin: 2px 0 2px 8px;
      position: relative;
      opacity: 1;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('./img/a-edit.svg') no-repeat center;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__delete {
      width: 16px;
      height: 16px;
      margin: 2px 0 2px 8px;
      position: relative;
      opacity: 1;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url('./img/a-delete.svg') no-repeat center;
        background-size: 21px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .lang-all {
    flex-grow: 1;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 24px;
    margin-top: 24px;

    &_edit {
      border-width: 0;
    }

    button {
      width: 230px;
    }
  }

  .select-my {
    margin: 0;
    width: 230px;

    &__menu {
      z-index: 110000 !important;
    }
  }
}
