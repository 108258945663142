.slick-arrow {
  background: #fff url(./img/arrow_left.svg) no-repeat 50%;
  border-radius: 50%;
  box-shadow: 0 8px 24px rgba(0, 72, 135, 0.1);
  width: 40px;
  height: 40px;
  font-size: 0;
  border: 0;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
  top: -32px;
  right: 0;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.slick-prev {
  right: 64px;
}
.slick-next {
  transform: rotate(180deg);
}
