.right-block-my-lk {
  &__buttons {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    margin-top: 24px;
  }

  &__button-my-order {
    margin: 24px 40px 0;
  }
}
