.rectangle {
  font-size: 14px;
  margin: 24px 0 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: #f2f6fa;

  div {
    + div {
      margin-top: 16px;
    }

    b {
      color: #000;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline !important;
      border-bottom: 1px solid;
    }
  }
}

.trek_card_bottom {
  position: relative;
  width: 100%;
  min-height: 200px;
  border-radius: 8px;
  background: #f2e5df;
  box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
  margin-top: 130px;
  display: flex;
  justify-content: flex-end;

  .img {
    height: 237px;
    position: absolute;
    left: 150px;
    bottom: 24px;

    @media (max-width: 1400px) {
      left: 30px;
    }

    @media (max-width: 1600px) {
      left: 50px;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  .text {
    width: 50%;
    padding: 30px 15px;

    @media (max-width: 1400px) {
      right: 30px;
    }

    @media (max-width: 1600px) {
      right: 50px;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.2px;
      color: #000;
    }

    h2 {
      color: #000;
    }

    .btn_block {
      margin-top: 24px;
    }
  }
}

.detail-track {
  &__news {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
  }
}
