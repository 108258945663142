.slider-rewards {
  width: 100%;
}

.awards_slider * {
  transition: none;
}

.awards_slider_prev,
.awards_slider_next {
  background: #fff url('../../../global/img/arrow_left.svg') no-repeat 50%;
  border-radius: 50%;
  box-shadow: 0 8px 24px rgba(0, 72, 135, 0.1);
  width: 40px;
  height: 40px;
  font-size: 0;
  border: 0;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.awards_slider_prev {
  right: initial;
  left: 0;
}

.awards_slider_next {
  transform: rotate(180deg);
  box-shadow: 0 -8px 24px rgba(0, 72, 135, 0.1);
}

.awards_slider {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .award {
    width: 90px;
    height: 90px;
    position: relative;
    margin: 0 auto;
    filter: grayscale(100);
    opacity: 0.25;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &.received {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
