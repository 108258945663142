@import 'colors';

#left_menu {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, #63569f, $french-blue);
  width: 280px;

  .site_name {
    background: #7563c7 url('../img/tintel-logo.svg') no-repeat center;
    width: 100%;
    height: 80px;
    padding: 12px 24px;
  }

  .menu_list {
    padding: 24px 16px;
    overflow: auto;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 104px;

    .menu_item {
      display: flex;
      flex-wrap: wrap;
      padding: 8px;
      border-radius: 40px;

      & + .menu_item {
        margin-top: 4px;
      }

      .icon {
        width: 50px;
        height: 50px;
        background-color: $blueberry;
        display: block;
        border-radius: 50%;
        padding: 9px;

        & > span {
          font-size: 25px;
          line-height: 32px;
          display: block;
          width: 32px;
          text-align: center;
          color: #fff;
        }
      }

      .item_name {
        color: #fff;
        display: block;
        padding: 13px 16px;
        width: calc(100% - 50px);
        font-weight: 400;
      }

      &.active {
        background-color: $light-indigo !important;

        .icon {
          background: #fff;

          & > span {
            color: $light-indigo;
          }
        }
      }

      &:hover {
        background-color: none;

        .icon {
          background: #fff;

          & > span {
            color: $light-indigo;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;

    .copy {
      opacity: 0.5;
      margin-top: 8px;

      span {
        font-size: 14px;
        line-height: 1.43;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 1650px) {
    width: 98px;

    .site_name {
      padding: 12px 21px;
      background: #7563c7 url('../img/tintel-logo-mini.svg') no-repeat center;
    }

    .menu_list {
      padding: 24px 0;
      bottom: 0;

      .menu_item {
        display: block;
        padding: 8px;
        border-radius: 0;

        & + .menu_item {
          margin-top: 8px;
        }

        .icon {
          margin: 0 auto;
        }

        .item_name {
          margin-top: 4px;
          padding: 0;
          width: 100%;
          text-align: center;
        }
      }
    }

    .footer {
      display: none;
    }
  }

  &.expand {
    width: 98px;

    .site_name {
      padding: 12px 21px;

      .text {
        display: none;
      }
    }

    .menu_list {
      padding: 24px 0;
      bottom: 0;

      .menu_item {
        display: block;
        padding: 8px;
        border-radius: 0;

        & + .menu_item {
          margin-top: 8px;
        }

        .icon {
          margin: 0 auto;
        }

        .item_name {
          margin-top: 4px;
          padding: 0;
          width: 100%;
          text-align: center;
        }
      }
    }

    .footer {
      display: none;
    }
  }
}
