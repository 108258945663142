.activity-card {
  &_100w {
    width: 100%;
    height: min-content;

    .activity-card__content {
      position: relative;
      padding: 32px;
    }
  }
}
