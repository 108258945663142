.login_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
}

form .terms.gray {
  padding: 4px 10px;
  z-index: 1;
  opacity: 1;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 500;
  display: block;
  color: rgba(0, 0, 0, 0.5);

  p {
    margin-bottom: 15px;
  }

  a {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 500;
  }
}
