.order {
  &__button-yet {
    width: 232px;
    margin: 40px auto 104px;
  }

  &__subscription {
    margin-top: 104px;
  }
}
