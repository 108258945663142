.status-mark {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.43;
  letter-spacing: 0.3px;
  text-align: center;
  display: inline-block;
  border-radius: 22px;
  background: #1588ee;
  color: #fff;
  padding: 2px 16px;
  user-select: none;

  &_purple {
    background: #7563c7;
  }

  &_turquoise {
    background: #2ed0d3;
  }

  &_yellow {
    background: #ffc500;
  }

  &_green {
    background: #00cd6a;
  }
}
