.edit {
  background: url(../../../old/img/icons/a-more_v.svg) no-repeat 50%;
  bottom: 0;
  width: 24px;
  cursor: pointer;
  z-index: 6;
  opacity: 0.5;
  margin-left: 16px;
  position: relative;

  &_open,
  &:hover {
    opacity: 1;
  }

  &__popup {
    position: absolute;
    right: -9px;
    top: 35px;
    padding: 6px 0;
    z-index: 5;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.15);
    border-radius: 8px;
    background: #fff;
    opacity: 1;
    pointer-events: auto;

    &:before {
      content: '';
      position: absolute;
      right: 10px;
      top: -22px;
      border: 11px solid transparent;
      border-bottom: 11px solid #fff;
    }

    a {
      display: flex;
      color: #000;
      font-size: 14px;
      padding: 8px 16px;
      font-weight: 400;

      span {
        line-height: 24px;
        padding: 0 0 0 12px;
        white-space: nowrap;
      }

      &:hover {
        background: #f2f6fa;
      }

      &.red {
        color: #dc3545;

        &:hover {
          background: rgba(220, 53, 69, 0.1);
        }
      }
    }
  }
}
