.table {
  padding: 0;

  .table-head .row,
  .table-row .row {
    display: grid;
    grid-template-columns: 6fr 4fr 2fr;
  }

  .table-head .row.row-recommend,
  .table-row .row.row-recommend {
    grid-template-columns: 4fr 3fr 4fr 1fr;
  }
}
