.user-ico {
  .ava {
    background-image: url('../../old/img/photo/ava2.png');
  }
}

.top_list {
  .line {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    margin-bottom: 10px;

    .dots_users {
      padding: 12px;
      width: 12.5%;

      span {
        margin: 20px auto 0;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: #f2f6fa url(../../old/img/dots.svg) no-repeat 50%;
      }
    }
  }
}

.user {
  text-align: center;
  display: block;
  .ava {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    margin: 0 auto;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0 solid #7563c7;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
    }

    .points {
      position: absolute;
      top: -4px;
      right: 0;
      font-size: 14px;
    }
  }

  .name {
    margin-top: 8px;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #000;
      line-height: 1.43;
    }
  }

  &:hover a {
    .ava {
      &:before {
        border-width: 5px;
      }

      .points {
        top: 0;
        right: 4px;
      }
    }

    .name {
      opacity: 0.75;

      span {
        color: #7563c7;
      }
    }
  }

  &.select {
    width: 100% !important;
    text-align: left;
    display: flex;
    padding: 10px 0 !important;

    .ava {
      margin: 0 !important;
      margin-left: calc(12.5% - 40px) !important;

      .points {
        background-color: #00cd6a;
      }
    }

    .text {
      padding-left: 24px;

      .name {
        opacity: 1;
        margin-bottom: 4px;

        span {
          text-align: left !important;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .info span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;

        + span {
          color: #7563c7;
          font-weight: 700;
        }
      }
    }
  }
}

.user {
  .select {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.15) url(../../old/img/icons/a-check-white.svg) no-repeat 50%;
    position: absolute;
    top: -4px;
    right: -4px;
    opacity: 0;
    z-index: 1;

    &_active {
      opacity: 1;
      background-color: #1588ee;
    }
  }

  &:hover .select {
    opacity: 1;
  }
}
