.competence_points_card {
  height: 152px;
  width: 280px;
  margin: 12px 24px 12px 0;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: solid 1px #f2f6fa;

  display: flex;
  flex-wrap: wrap;

  &.orange {
    background-color: #ff800b;

    .points {
      background-color: #ff800b;
    }
  }

  &.red {
    background-color: #ff4b3a;

    .points {
      background-color: #ff4b3a;
    }
  }

  &.yellow {
    background-color: #ffc500;

    .points {
      background-color: #ffc500;
    }
  }

  &.green {
    background-color: #00cd6a;

    .points {
      background-color: #00cd6a;
    }
  }

  &.blue {
    background-color: #1588ee;

    .points {
      background-color: #1588ee;
    }
  }

  &.dark_blue {
    background-color: #534a9c;

    .points {
      background-color: #534a9c;
    }
  }

  &.not {
    background-color: #f2f6fa;
    box-shadow: none;
    border-radius: 8px;

    .text {
      background-color: #f2f6fa !important;
    }
  }

  &.not_active {
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0);

    .points {
      background: #f2f6fa;

      span {
        color: #bfbfbf;
      }

      .select_icon {
        opacity: 0;
      }
    }
  }

  .text {
    margin-left: 4px;
    background-color: #fff;
    height: 100%;
    padding: 16px;
    width: calc(100% - 68px);
    position: relative;

    span {
      font-size: 11px;
    }
  }

  .points {
    position: relative;
    width: 64px;
    text-align: center;
    padding: 43px 0;

    .select_icon {
      width: 24px;
      height: 24px;
      background: url('../../old/img/icons/a-check-white.svg');
      position: absolute;
      top: 12px;
      left: 20px;
      opacity: 1;
    }

    span {
      display: block;
      color: #fff;
      font-size: 40px;
      font-weight: 400;
      line-height: 1.4;

      + span {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        margin-top: -10px;
      }
    }
  }
}
