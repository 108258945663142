@import '../../global/colors.scss';

.top-menu {
  flex-wrap: nowrap;
  z-index: 11;
  // position: fixed;
  left: 280px;
  right: 0;
  top: 0;
  height: 80px;
  background-color: #ffffff;
  display: flex;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $very-light-pink;
  }

  &__who-rules-null {
    text-align: center;
  }

  &__user-login {
    position: relative;
    height: 80px;
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 20px 12px;
    height: 40px;
    padding: 8px 16px;
  }

  &__the-best-name {
    width: 108px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .user_pages {
    display: flex;
    padding: 0 12px;
    position: relative;

    a {
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.1px;
      color: #000000;
      display: block;
      padding: 28px 12px;
      opacity: 0.25;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        right: 50%;
        opacity: 0;
        height: 3px;
        background-color: $deep-sky-blue;
        transition: all 0.15s ease-in-out;
      }

      &:hover,
      &.active {
        color: $deep-sky-blue;
        opacity: 1;

        &:before {
          opacity: 1;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .the_best {
    flex-wrap: nowrap;
  }

  .icons {
    display: flex;
    position: relative;

    .open_list_btn,
    .link_btn {
      padding: 12px;
      position: relative;
      cursor: pointer;

      .indicator {
        width: 8px;
        height: 8px;
        background-color: $deep-sky-blue;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        right: 6px;
      }

      .icon {
        color: #868686;
        opacity: 0.5;
        font-size: 24px;
        display: block;
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }

      &.active {
        .icon {
          opacity: 1;
          color: #1488ee;
        }
      }
    }
  }

  .user {
    display: flex;
    position: relative;
    cursor: pointer;

    span {
      opacity: 0.5;
      font-size: 14px;
      line-height: 50px;
      text-align: right;
      color: #000000;
      padding: 0 16px 0 0;
      font-weight: 400;
      width: 112px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ava {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.active {
      & + .menu {
        opacity: 1;
        top: 92px;
        pointer-events: auto;
      }
    }
  }

  .menu {
    position: absolute;
    right: 14px;
    width: 154px;
    top: 95px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
    background-color: #ffffff;
    border-radius: 8px;
    padding: 8px 0;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    cursor: pointer;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &:before {
      content: '';
      position: absolute;
      right: 23px;
      top: -22px;
      border: 11px solid transparent;
      border-bottom: 11px solid #fff;
    }

    a {
      display: block;
      font-size: 16px;
      line-height: 44px;
      height: 44px;
      font-weight: normal;
      color: #000000;
      text-align: left;
      padding: 0 16px 0 56px;
      position: relative;

      &:hover {
        background: rgba(241, 241, 241, 0.5);
      }

      img {
        top: 10px;
        left: 16px;
        position: absolute;
        width: 24px;
        height: 24px;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #f1f1f1;
    }
  }

  .notifications_popup {
    .event_alert {
      padding: 16px;
    }

    .event_alert_center {
      .text {
        text-align: center;
      }
    }
  }

  .geoLocation__place {
    white-space: nowrap;
  }

  &.expand {
    left: 98px;
  }

  .top-menu-right-block {
    display: flex;
  }

  @media (max-width: 1650px) {
    left: 98px;
  }

  @media (max-width: 1500px) {
    .user {
      span {
        display: none;
      }
    }
  }

  @media (max-width: 1380px) {
    .the_best {
      > div {
        width: 120px;
      }

      > div + div {
        margin: 0;
      }

      .ico {
        display: none;
      }
    }

    .geoLocation__place {
      margin: 30px 10px;
    }
  }

  @media (max-width: 1160px) {
    .the_best {
      > div {
        width: 120px;
      }

      > div + div {
        margin: 0;
      }

      .ico {
        display: none;
      }
    }

    .geoLocation__place {
      margin: 30px 10px;
    }
  }
}

.notifications {
  display: block;
  color: #ff4b3a;
  font-size: 11px;
  line-height: 1.43;
  margin-top: 16px;
}
