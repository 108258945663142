.chart {
  &__labels,
  .apexcharts-datalabel {
    font-family: 'Roboto';
    font-size: 14px;
    word-wrap: break-word;
    width: 130px !important;
    display: block;
  }
}
