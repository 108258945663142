.attachment {
  &__title {
    margin: 40px 0 24px;
    font-size: 16px;
  }

  &__file {
    background: url(../../../old/img/icons/a-file.svg) no-repeat 0 0;
    padding-left: 32px;
    font-weight: 400;
    min-height: 24px;
    display: inline-block;
    margin-right: 20px;

    &:hover {
      color: #0056b3;
    }
  }
}
