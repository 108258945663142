.tabs-with-content {
  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;

    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0 0 24px;
  }

  &__tabs-item {
    position: relative;
    opacity: 1;
    color: rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid transparent;
    margin-right: 24px;
    padding-bottom: 8px;
    cursor: pointer;

    &:hover:not(.tabs-with-content__tabs-item_active) {
      color: rgba(0, 0, 0, 0.5);
      opacity: 1;

      > .tabs-with-content__subtabs {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__tabs-item_active {
    color: #1588ee;
    opacity: 1;
    border-bottom: 2px solid #1588ee;
  }

  &__tabs-item_dropdown {
    display: flex;
    justify-content: center;

    &:after {
      content: '';
      background: url(../../../old/img/icons/a-arrow_down.svg) no-repeat;
      width: 24px;
      height: 20px;
      display: inline-block;
      transition: all 0.15s ease-in-out;
    }

    &:before {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
    }

    &:hover {
      transition: all 0.15s ease-in-out;

      > .tabs-with-content__subtabs {
        opacity: 1;
      }

      &:after {
        transform: rotate(180deg) translateY(-4px);
      }
    }
  }

  &__subtabs {
    position: absolute;
    top: 36px;
    width: auto;
    min-width: 160px;
    background: #fff;
    padding: 8px 16px;
    border-radius: 22px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
    border: solid 1px #f1f1f1;
    opacity: 0;
    transition: all 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &__subtabs_active {
    display: block;
  }

  &__subtabs-item {
    display: block;
    margin: 0;
    text-align: left;
    padding: 10px 0;
    cursor: pointer;

    &:hover:not(.tabs-with-content__subtabs-item_active) {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__subtabs-item_active {
    color: #1588ee;
  }
}
