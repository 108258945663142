.input-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &__input {
    display: block;
    width: 100%;
    border-radius: 22px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    &:focus {
      border-color: #1588ee;
    }
  }

  &__input_error {
    border: 1px solid #ff4b3a !important;
  }

  &__notification {
    color: #ff4b3a;
    font-size: 11px;
    line-height: 1.43;
    margin: 4px 0 0 16px;
    position: absolute;
    top: 43px;
    left: 0;
  }

  &__notification_server {
    font-size: 12px;
    margin-left: 0;
  }

  &__notification_server-success {
    color: rgb(67, 201, 67);
  }
}

.input {
  position: relative;
}
