.modal-ideas-matrix {
  padding: 0 24px 24px 24px;

  input[type='file'] {
    position: absolute;
    opacity: 0;
  }

  textarea {
    font-family: Roboto;
  }

  &__attach {
    padding: 4px 25px;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 500;

    position: relative;
    z-index: 1;
    display: block;
    padding-left: 32px;
    height: 24px;
    background: url(../../../old/img/icons/a-attach_file.svg) no-repeat 0;
    opacity: 1;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  &__add-files {
    display: flex;
  }

  &__delete-files {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
