@import '../../global/colors.scss';

.select-my {
  margin-bottom: 24px;

  &__control {
    width: 100% !important;
    padding: 0 0 0 25px;
    height: 44px;
    border-radius: 22px !important;
    border: 1px solid #f1f1f1 !important;
    background: #fff;
    font-size: 16px;
    line-height: 1.5;

    &_error {
      border: 1px solid #ff4b3a !important;
    }

    &--is-focused {
      box-shadow: none !important;
    }

    &--menu-is-open {
      border-radius: 22px 22px 0 0 !important;
      border-bottom: 0 !important;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 24px;
        right: 16px;
        height: 1px;
        background: #f1f1f1;
      }
    }
  }

  &__value-container {
    padding: 0 !important;
  }

  &__placeholder {
    color: #000 !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__menu {
    margin: 0 !important;
    box-shadow: none !important;
    border: 1px solid #f1f1f1 !important;
    border-top: 0 !important;
    border-radius: 0 0 22px 22px !important;
  }

  &__menu-list {
    padding: 8px 0 !important;
  }

  &__option {
    padding: 5px 25px !important;

    &--is-selected {
      background: transparent !important;
      color: $deep-sky-blue !important;
      font-weight: 700 !important;
    }

    &--is-focused {
      background: #e9e9e9 !important;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}

/*.selectboxit-container {
  width: 100%;

  .selectboxit {
    width: 100% !important;
    padding: 0 25px;
    height: 44px;
    display: block;
    border-radius: 22px;
    border: 1px solid #f1f1f1;
    background: #fff;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    .selectboxit-arrow-container {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 10px;

      .selectboxit-arrow {
        width: 24px;
        height: 24px;
        border: 0;
        background: url(../../old/img/icons/a-arrow_down.svg);
        margin: 0;
        position: relative;
        top: 0;
        display: block;
      }
    }

    .selectboxit-option-icon-container {
      margin: 0 !important;
    }

    .selectboxit-text {
      text-indent: 0;
      padding-right: 50px;
    }

    span {
      height: auto;
      line-height: 1.5;
      font-size: 16px;
    }

    &.selectboxit-open {
      border-color: #f1f1f1;
      border-bottom: 1px solid #fff;
      border-radius: 22px 22px 0 0;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 24px;
        right: 16px;
        height: 1px;
        background: #f1f1f1;
      }

      + .selectboxit-options {
        position: absolute;
        top: 43px !important;
        left: 0;
        right: 0;
        bottom: auto !important;
      }
    }
  }

  .selectboxit-options {
    min-width: 100% !important;
    max-height: 250px;
    border-radius: 0 0 22px 22px;
    overflow: auto;
    padding: 8px 0;
    border-color: #f1f1f1;
    border-top: 0;

    li a {
      color: #000 !important;
      font-size: 16px;
      padding: 4px 23px;
      height: auto;
      line-height: 24px;
      text-indent: 0;
      white-space: pre-wrap;

      .selectboxit-option-icon-container {
        display: none;
      }
    }

    .selectboxit-focus a,
    li a:hover {
      background: hsla(0, 0%, 95%, 0.5);
    }

    .selectboxit-selected a {
      color: #1588ee !important;
      font-weight: 700;
      background: #fff;
    }

    .selectboxit-disabled {
      display: none;

      a {
        color: #000 !important;
        font-weight: 400 !important;
        opacity: 0.5;
      }
    }
  }
}
*/
