.terms-use {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__item {
    width: 250px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1588ee;
    word-wrap: break-word;

    &:hover {
      box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
      color: #1588ee;
    }
  }
}
