.header-left-menu-about {
  a {
    font-size: 14px;
    line-height: 1.43;
    color: #ffffff;
    opacity: 0.5;
    font-weight: 400;

    &:hover {
      opacity: 0.8;
    }
  }
}
