.competencies {
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
  }

  &__ways {
    width: 48%;
    margin-right: 15px;
  }

  &__track {
    display: grid !important;
    grid-template-rows: 16% 73% 11%;
    height: 411px;
    cursor: pointer;

    &:hover {
      .competencies__map {
        opacity: 1;
      }

      .competencies__track-name {
        opacity: 1;
      }
    }
  }

  &__track_active {
    .competencies__map {
      opacity: 1;
    }

    .competencies__track-name {
      opacity: 1;
      color: #1588ee;
    }
  }

  &__track-name {
    text-align: center;
    opacity: 0.5;
    color: #000;
    font-size: 14px;
    overflow-wrap: break-word;
    margin: auto;
    font-weight: bold;
  }

  &__img {
    width: 122px;
    margin: auto;
  }

  &__map {
    opacity: 0.35;
    width: 100%;

    &:hover {
      opacity: 1;
    }
  }

  &__position {
    position: absolute;
    z-index: 1;
  }

  &__track-text {
    font-size: 14px;
    text-align: center;
    margin: auto;
  }

  &__history-car {
    width: 353px;
    margin-bottom: 40px;
  }

  &__history-cones {
    width: 160px;
    margin-bottom: 40px;
  }

  &__history-map_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .competencies__history-text {
      width: 60%;
      text-align: center;
    }
  }

  &__history-map {
    border-radius: 8px;
    padding: 24px;
    background: #f2f6fa;
    width: 52%;

    .title {
      h5 {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .link {
      margin-top: 6px;
      a {
        font-size: 14px;
        font-weight: bold;
        color: #1588ee;
        display: inline-block;
        line-height: 24px;
        padding-right: 24px;
        position: relative;
        svg {
          display: block;
          position: absolute;
          right: 8px;
          top: 6px;
          width: 7px;
          transform: rotate(180deg);
        }
      }
    }
    .descr span {
      display: block;
      height: 40px;
      line-height: 20px;
      font-size: 14px;
    }

    .list > div {
      margin-top: 20px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      align-items: center;
      position: relative;

      .icon {
        width: 35px;
      }

      span {
        width: calc(100% - 35px);
        display: block;
        padding-left: 16px;
        font-size: 14px;
      }
    }
    .num_list {
      div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        span {
          display: block;
          &:first-child {
            width: 20px;
            height: 20px;
            background: #4167bb;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
          }
          & + span {
            padding-left: 8px;
            line-height: 20px;
            font-size: 14px;
            color: #000;
          }
        }
      }
    }
    .content_history_map {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 353px;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
      }
      p {
        display: block;
        margin: 40px 0 0 0;
      }
      &.car {
        img {
          width: 353px;
        }
      }
      &.cones {
        img {
          width: 160px;
        }
      }
    }
  }

  .slider-competencies,
  .slider-tracks {
    .slick-arrow {
      top: 0;
    }

    .slick-prev {
      left: 12%;
      z-index: 15;
    }
    .slick-next {
      right: 12%;
    }
  }

  .slider-tracks {
    .competencies__slide-tracks {
      width: 100%;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .slick-slide {
      .competencies__track {
        &:nth-child(4n + 1) {
          color: #ff4b3a;
        }
        &:nth-child(4n + 2) {
          color: #ff800b;
        }
        &:nth-child(4n + 3) {
          color: #ffc500;
        }
        &:nth-child(4n + 4) {
          color: #00cd6a;
        }
      }
    }
  }

  .pin {
    text-align: center;
    line-height: 40px;
    opacity: 0;
    height: 50px;

    .name span {
      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      transition: all 0.15s ease-in-out;
      color: #000;
    }

    &.active {
      opacity: 1;
    }
  }

  .roads_list {
    position: relative;
    height: 40px;
    width: 100%;

    .next,
    .prev {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      opacity: 0.75;
      transition: all 0.15s ease-in-out;
      cursor: pointer;
      z-index: 1;
      border-radius: 50%;
    }

    .next:hover,
    .prev:hover {
      opacity: 1;
    }

    .next.not-active,
    .prev.not-active {
      opacity: 0;
      cursor: default;
    }

    .next {
      transform: rotate(180deg);
      box-shadow: 0 -8px 24px rgba(0, 72, 135, 0.2);
      right: 50%;
      margin-right: -231px;
      background: #fff url(../../../old/img/icons/arrow_left.svg) no-repeat 50%;
    }

    .prev {
      background: #fff url(../../../old/img/icons/arrow_left.svg) no-repeat 50%;
      box-shadow: 0 8px 24px rgba(0, 72, 135, 0.2);
      left: 50%;
      margin-left: -231px;
    }
  }

  .roads_in_sphere {
    background: #fff;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    bottom: 70px;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-in-out;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    .sphere {
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      pointer-events: none;
      transition: all 0.15s ease-in-out;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      > div {
        width: 25%;
        padding: 0 2px;
        position: relative;
        cursor: pointer;

        .name {
          position: absolute;
          top: 0;
          left: 10px;
          right: 10px;
          height: 60px;
          text-align: center;

          span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            opacity: 0.5;
            color: #000;
            font-size: 14px;
            overflow-wrap: break-word;
          }
        }

        .img {
          margin-top: 67px;
          margin-bottom: 44px;
          position: relative;
          width: 100%;
        }

        .start_marker {
          position: absolute;
          bottom: 0;
          left: 10px;
          right: 10px;
          height: 40px;
          text-align: center;

          span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            font-size: 14px;
          }
        }

        &.active {
          cursor: default;

          .name span {
            opacity: 1;
            color: #1588ee;
          }
        }

        &:first-child .start_marker span {
          color: #ff4b3a;
        }

        &:nth-child(2) .start_marker span {
          color: #ff800b;
        }

        &:nth-child(3) .start_marker span {
          color: #ffc500;
        }

        &:nth-child(4) .start_marker span {
          color: #00cd6a;
        }
      }
    }
  }

  .treks_popup {
    background: #f2f6fa;
    border-radius: 8px;

    transition: all 0.15s ease-in-out;

    &.active {
      opacity: 1;
      pointer-events: auto;
      transition: all 0.15s ease-in-out;
    }

    &:before {
      content: '';
      position: absolute;
      left: -32px;
      top: 50%;
      margin-top: -16px;
      border: 16px solid transparent;
      border-right: 16px solid #f2f6fa;
    }

    .treks_name_list span {
      display: block;
      font-size: 14px;
      padding: 8px 0;
      cursor: pointer;
      z-index: 5;
      color: #000;

      &:after {
        content: '';
        display: none;
        position: absolute;
        height: 1px;
        background: #000;
        opacity: 0.2;
      }

      &:before {
        content: '';
        display: none;
        position: absolute;
        height: 1px;
        background: #000;
        opacity: 0.2;
        left: 0;
        bottom: 0;
        width: 90%;
      }

      &:after {
        left: 90%;
      }

      &:first-child {
        &:after {
          width: 100%;
          transform: rotate(10deg);
          bottom: -10px;
          margin-left: -1px;
        }
      }

      &:nth-child(2) {
        top: 192px;
        left: 15px;
        right: calc(50% + 120px);

        &:after {
          width: 50%;
          transform: rotate(-13deg);
          bottom: 6px;
          margin-left: -1px;
        }
      }

      &:nth-child(3) {
        bottom: 140px;
        left: 15px;
        right: calc(50% + 120px);

        &:after {
          width: 55%;
          transform: rotate(-32deg);
          bottom: 16px;
          margin-left: -5px;
        }
      }

      &:nth-child(4) {
        bottom: 40px;
        left: 65px;
        right: calc(50% + 70px);

        &:after {
          width: 120%;
          transform: rotate(-27deg);
          bottom: 30px;
          margin-left: -7px;
        }
      }

      &:hover {
        color: rgba(0, 0, 0, 0.75);
      }

      &.active {
        cursor: default;
        opacity: 1;
        color: #1588ee;
      }
    }

    .title {
      position: relative;
      padding-right: 32px;

      .close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: block;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: #000;
        opacity: 0.25;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .descr span {
      display: block;
      height: 40px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 300;
    }

    .treks_donut {
      height: 284px;
      width: 132px;
      overflow: hidden;

      justify-self: center;

      svg {
        height: 284px;
        width: 264px;
        transform: rotate(109deg);
      }
    }

    .competencies_block {
      padding: 16px 12px 12px;
      background: #e6f0fa;
      border-radius: 8px;
      min-height: 310px;

      @media (max-width: 1650px) {
        bottom: 15px;
        overflow: auto;
      }

      h6 {
        margin-bottom: 16px;
        font-size: 16px;
      }

      .competence {
        cursor: pointer;
        padding: 4px 8px 6px;
        border-radius: 8px;
        border: 1px dashed rgba(65, 103, 187, 0.25);

        span {
          display: block;
          opacity: 0.5;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.43;
        }

        .line {
          margin-top: 8px;
          position: relative;
          background: rgba(23, 135, 238, 0.25);
          height: 2px;
          width: 100%;

          div {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: #1588ee;
            height: 2px;
          }
        }

        + .competence {
          margin-top: 8px;
        }
      }
    }
  }
}

.history-map {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }

  &__link {
    font-weight: bold;
    margin: 6px 0 24px;

    a {
      font-size: 14px;
      margin-right: 8px;

      &:hover {
        color: #0056b3;
      }
    }

    svg {
      display: inline-block;
      transform: rotate(180deg);
      vertical-align: middle;
    }
  }

  &__tracks {
    width: 140px;
    height: 100%;
    position: relative;
    justify-self: center;
  }

  &__tracks-list {
    span {
      width: 167px;
      margin: 40px 0;
      padding: 0;
      text-align: center;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &:first-child {
        margin-top: 0;
        margin-left: 75px;
      }
      &:last-child {
        margin-bottom: 0;
        margin-left: 75px;
      }
    }
  }

  &.inroads {
    .descr span {
      display: block;
      height: 30px;
      line-height: 20px;
      font-size: 14px;
      font-weight: bold;
      margin-top: 24px;
    }

    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -32px;
      top: 50%;
      margin-top: -16px;
      border: 16px solid transparent;
    }
  }
}

.history-start {
  &__text_bold {
    font-weight: bold;
    font-size: 14px;
    margin: 24px 0 16px;
  }
}

.recomm_card {
  height: 108px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .name,
  .pionts,
  .time {
    position: absolute;
    display: block;
    z-index: 1;
  }

  .where {
    position: absolute;
    display: block;
    z-index: 1;
    top: 16px;
    left: 16px;
    color: #fff;
    text-transform: uppercase;
    opacity: 0.75;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  .pionts {
    font-size: 14px;
    top: 0;
    right: 12px;
    width: 56px;
    height: 77px;
    background: #7563c7;
    color: #fff;
    text-align: center;
    line-height: 24px;
    padding-top: 12px;

    span {
      font-size: 40px;
      font-weight: 400;
      line-height: 38px;
      display: block;

      + span {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &:before {
      left: 0;
      border: 15px solid transparent;
      border-left: 28px solid #7563c7;
      border-top: 0 solid #7563c7;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -15px;
    }

    &:after {
      right: 0;
      border: 15px solid transparent;
      border-top: 0 solid #7563c7;
      border-right: 28px solid #7563c7;
    }
  }

  .time {
    top: 16px;
    right: 16px;
    color: #fff;
    opacity: 0.75;
    font-size: 14px;
    line-height: 20px;
    padding-left: 28px;
    background: url(../../../old/img/icons/a-time.svg) no-repeat 0;
  }

  .name {
    bottom: 16px;
    left: 16px;
    right: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
  }

  .bg {
    overflow: hidden;
    z-index: 0;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.7;
      background-color: #fff;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }

  &.points_right {
    padding-right: 80px;

    .name {
      right: 80px;
    }

    &.blue .pionts {
      background: #1588ee;

      &:before {
        border-left: 28px solid #1588ee;
        border-top: #1588ee;
      }

      &:after {
        border-top: #1588ee;
        border-right: 28px solid #1588ee;
      }
    }

    &.red .pionts {
      background: #ff4b3a;

      &:before {
        border-left: 28px solid #ff4b3a;
        border-top: #ff4b3a;
      }

      &:after {
        border-top: #ff4b3a;
        border-right: 28px solid #ff4b3a;
      }
    }

    &.green .pionts {
      background: #00cd6a;

      &:before {
        border-left: 28px solid #00cd6a;
        border-top: #00cd6a;
      }

      &:after {
        border-top: #00cd6a;
        border-right: 28px solid #00cd6a;
      }
    }

    &.orange .pionts {
      background: #ff800b;

      &:before {
        border-left: 28px solid #ff800b;
        border-top: #ff800b;
      }

      &:after {
        border-top: #ff800b;
        border-right: 28px solid #ff800b;
      }
    }

    &.yellow .pionts {
      background: #ffc500;

      &:before {
        border-left: 28px solid #ffc500;
        border-top: #ffc500;
      }

      &:after {
        border-top: #ffc500;
        border-right: 28px solid #ffc500;
      }
    }
  }
}

.recomm_cards {
  width: 360px;

  .slick-arrow {
    background: #f2f6fa url(../../../old/img/icons/a-arrow_left_24px.svg) no-repeat 50%;
    width: 32px;
    height: 32px;
    font-size: 0;
    border: 0;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 125px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.7;
    z-index: 1;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
    transform: rotate(180deg);
  }

  .recomm_card_slider {
    .recomm_card {
      margin: 0;
    }
  }
  .counter {
    text-align: center;
    opacity: 0.25;
    font-size: 14px;
    color: #000000;
    margin: 15px 0 6px 0;
  }
}
