.blacklist {
  display: block;

  &__users {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 24px;
  }
}

.blacklist_buttons {
  padding-top: 30px;
}

.blacklist_buttons input {
  margin-left: auto;
  width: 200px;
  display: block;
}
