.content-modal {
  &__hobby {
    max-height: 240px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__button {
    margin-left: auto;
  }

  .hobby {
    margin: 8px 20px 8px 0;
    opacity: 0.25;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }

  .hobby-all {
    flex-grow: 1;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    margin-top: 24px;
  }
}
