.slider-gallery {
  &__image {
    height: 300px;
    background-color: #333;
    margin: 40px 8px 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
