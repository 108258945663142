.my-work-card {
  height: 344px;
  width: 320px;
  display: inline-block;
  margin: 24px 12px;
  border-radius: 8px;
  border: 1px solid rgba(191, 191, 191, 0.25);
  background-color: #fff;

  position: relative;
  overflow: hidden;

  &__img {
    position: relative;
    height: 196px;
    background-image: url(../../img/bg/image.jpg);
    background-size: cover;
  }

  &__content {
    padding: 16px;
  }

  &__title {
    line-height: 24px;
    white-space: normal;
    margin-bottom: 12px;
  }

  .sign-board + .sign-board {
    margin-top: 8px;
  }

  .like {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
