$blueberry: #534a9c;
$shamrock-green: #00cd6a;
$deep-sky-blue: #1588ee;
$pumpkin-orange: #ff800b;
$light-indigo: #7563c7;
$french-blue: #4167bb;
$marigold: #ffc500;
$pale-grey: #f2f6fa;
$very-light-pink: #f2f2f2;
$coral: #ff4b3a;

.yellow {
  background-color: #ffc500;
}

.orange {
  background-color: #ff800b;
}

.green {
  background-color: #00cd6a;
}

.red {
  border-color: #ff4b3a;
}

.gray {
  background-color: #bfbfbf;
}

.blue {
  background-color: #4167bb;
}

.turquoise {
  background-color: #2ed0d3;
}
