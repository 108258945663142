.page_settings {
  &.settings {
    input,
    .select-my {
      width: 320px;
    }

    input[name='status'] {
      width: 100%;
    }

    input[type='submit'] {
      width: auto;
    }

    .page__column-2 {
      :nth-child(2n) {
        justify-self: end;
      }
    }

    .settings__ava {
      display: flex;
      flex-wrap: wrap;

      .new_ava {
        position: relative;
        width: 130px;

        .upload_list {
          position: absolute;
          top: 0;
          right: 0;
          width: 130px;
          height: 130px;
          margin: 0;

          span {
            display: block;
            width: 100%;
            height: 100%;

            .img-thumbnail {
              margin: 0;
            }
          }
        }

        .current_ava,
        .upload_list span .img-thumbnail {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          background: #333;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;
        }

        .add_new_ava_input {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 6;

          label {
            position: relative;
            z-index: 1;
            display: block;
            width: 32px;
            height: 32px;
            background: url(../../old/img/icons/a-photo_change.svg) no-repeat 0;
            opacity: 1;
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }

          input {
            position: absolute;
            z-index: 0;
            opacity: 0;
            display: block;
            border: 0;
            padding: 0;
            margin: 0;
            width: 0;
            height: 0;
            pointer-events: none;
          }
        }
      }

      .input {
        width: calc(100% - 130px);
        padding-left: 24px;
      }
    }

    .input-radio-block {
      display: flex;
    }
  }
}
