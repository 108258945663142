.mertors_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .mentor {
    width: 240px;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
    margin: 12px;

    .img {
      margin: -15px;
      margin-bottom: 0;
      position: relative;

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
  }

  .add_new_card {
    width: 240px;
    margin: 12px;
  }
}
