.detal-chat {
  &__form-options {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 48px;
  }

  &__place-dialog {
    width: 100%;
  }
}
