.news {
  & + .news {
    margin-top: 28px;
  }
  .page_title {
    margin-bottom: 12px;
  }

  &__list-grid,
  &__list-grid_modal {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 24px;
    align-items: center;
    margin: 0 !important;

    :nth-child(3n + 1) {
      justify-self: start;
    }
    :nth-child(3n + 2) {
      justify-self: center;
    }
    :nth-child(3n + 3) {
      justify-self: end;
    }
  }

  &__list-grid {
    @media (max-width: 1490px) {
      grid-template-columns: 1fr 1fr;

      :nth-child(2n + 1) {
        justify-self: start;
      }
      :nth-child(2n + 2) {
        justify-self: end;
      }
    }
  }

  &__list-grid_4item {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    :nth-child(4n + 1) {
      justify-self: start;
    }
    :nth-child(4n + 2),
    :nth-child(4n + 3) {
      justify-self: center;
    }
    :nth-child(4n + 4) {
      justify-self: end;
    }

    @media (max-width: 1490px) {
      grid-template-columns: 1fr 1fr 1fr;

      :nth-child(3n + 1) {
        justify-self: start;
      }
      :nth-child(3n + 2) {
        justify-self: center;
      }
      :nth-child(3n + 3) {
        justify-self: end;
      }
    }
  }

  &__nofound {
    margin-left: 26px;
  }

  .news_list {
    &.col4 {
      .shadow-box,
      .add_new_card {
        width: calc(25% - 24px) !important;
      }
    }
    .shadow-box,
    .add_new_card {
      margin: 12px 12px;
      width: calc(33.333333333% - 24px);
      display: block;
    }
  }
}
