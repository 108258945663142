.navigator-card {
  width: 320px;
  height: 396px;
  border-radius: 8px;
  border: 1px solid rgba(191, 191, 191, 0.25);
  background-color: #fff;
  position: relative;
  overflow: hidden;

  &:hover {
    border: 1px solid rgba(191, 191, 191, 0.5);
  }

  &__img-block {
    height: 160px;
    position: relative;
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    min-height: calc(100% - 160px);
  }

  &__tag-line {
    opacity: 0.75;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #7563c7;
    margin-bottom: 12px;

    span {
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;

      + span {
        margin-left: 16px;
      }
    }
  }

  &__caption {
    margin-bottom: 12px;
    line-height: 24px;
    white-space: normal;
  }

  &__geo {
    padding-left: 28px;
    background: url(../../old/img/icons/a-place.svg) no-repeat;

    span {
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__text {
    margin: 12px 0;

    span {
      font-size: 14px;
      line-height: 1.43;
      font-weight: 400;
      color: #000;
      display: block;
    }
  }

  &__sign-boards-line {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__sign-board {
    padding-left: 28px;
    text-align: left;
  }

  &__sign-board-count {
    opacity: 0.25;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
  }

  &__a-card-education {
    background: url(../../old/img/icons/a-card-education.svg) no-repeat 0;
  }

  &__a-card-project {
    background: url(../../old/img/icons/a-card-project.svg) no-repeat 0;
  }

  &__a-card-events {
    background: url(../../old/img/icons/a-card-events.svg) no-repeat 0;
  }

  &__a-card-challenge {
    background: url(../../old/img/icons/a-card-challenge.svg) no-repeat 0;
  }

  /* ниже для прелоадера */

  &__preload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    animation: c 0.3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    &:before {
      content: '';
      display: block;
      background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff 50%, hsla(0, 0%, 100%, 0));
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      background-size: 200% 200%;
      animation: d 1s infinite;
    }
  }

  &__img-block_preload {
    background: #f2f2f2;
  }

  &__content_preload {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
  }

  &__tag-line_preload {
    span {
      width: 120px;
      height: 16px;
      border-radius: 8px;
      background: #f2f2f2;
      opacity: 0.75;
      font-family: Roboto;
      font-size: 12px;
      line-height: 20px;
      color: #7563c7;
    }
  }

  &__caption_preload {
    width: 200px;
    height: 24px;
    border-radius: 12px;
    background: #f2f2f2;
    margin: 13px 0;
  }

  &__geo_preload {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f2f2f2;
    padding: 0;

    span {
      position: absolute;
      width: 100px;
      height: 20px;
      border-radius: 12px;
      background: #f2f2f2;
      padding: 1px 40px;
      margin: 0 25px;
    }
  }

  &__text_preload {
    margin: 11px 0;

    span {
      width: 100%;
      height: 15px;
      margin: 5px 0;
      border-radius: 12px;
      background: #f2f2f2;

      &:last-child {
        width: 70%;
      }
    }
  }

  &__sign-boards-line_preload {
    height: 24px;
  }

  &__sign-board_preload {
    background: #f2f2f2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
  }
}
