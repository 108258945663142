.tabs__content {
  .people_list {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    row-gap: 44px;
    margin-top: 40px;

    & > div {
      width: calc(100% / 9);
    }
  }
}
