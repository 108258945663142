.geoLocation {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;

  &__place {
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
    margin: 30px 41px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('../../old/img/icons/icons-a-place.svg');
      margin-right: 8px;
    }
  }

  &__form {
    position: absolute;
    top: 70px;
    padding: 27px 20px 16px 16px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
    background-color: #fff;
    z-index: 2;
    min-width: 250px;
    display: none;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background: #fff;
      border-width: 1px 0px 0px 1px;
      border-style: solid;
      border-color: rgba(0, 72, 135, 0.03);
    }
  }

  &__form_open,
  .active {
    display: block;
  }

  &__form-text {
    margin-bottom: 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
  }

  &__button {
    height: auto;
    padding: 4px 16px;
    user-select: none;
  }
  &__button_yes {
    padding: 6px 16px;
    margin-right: 16px;
  }
}
