@import '../../global/colors.scss';

.treks {
  #trek_list {
    .trek {
      width: calc(100% / 3 - 24px);

      &:before {
        content: '';
        border-top: 17px solid #ff4b3a;
        bottom: -34px;
        transition: all 0.15s ease-in-out;
      }

      &:hover {
        background-color: #ff4b3a;
      }

      &.active {
        background-color: #ff4b3a;
      }
    }
  }

  .trek_content .trek {
    opacity: 0;
    transition: all 0.15s ease-in-out;
    &.active {
      opacity: 1;
    }

    .category {
      .treks_group {
        .trek_card {
          .bg {
            &:after {
              content: '';
              background-image: radial-gradient(circle at 50% 0, #4167bb, #7563c7);
            }
          }
        }

        .more_info {
          z-index: 5;
          animation: b 0.15s 1 ease-in-out;

          &.left {
            right: auto;

            &:before {
              left: auto;
            }
          }

          &.arrow_top:before,
          &.left.arrow_top:before {
            bottom: auto;
          }

          .scroll {
            &::-webkit-scrollbar-button {
              &:end:increment,
              &:start:decrement {
                display: none;
              }
            }

            .competences {
              .competence {
                i {
                  color: #4167bb;
                }
              }
            }
          }

          &.active {
            display: block;
            animation: a 0.15s 1 ease-in-out;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .treks #trek_list .trek {
    padding: 24px 10px;
  }
}

@keyframes show_trek {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide_trek {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.treks {
  #trek_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    :nth-child(3n + 1) {
      justify-self: start;
    }
    :nth-child(3n + 2) {
      justify-self: center;
    }
    :nth-child(3n + 3) {
      justify-self: end;
    }

    .trek {
      width: calc(100% - 16px);
      border-radius: 8px;
      box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
      background-color: #ffffff;
      padding: 24px;
      margin: 0 0 40px 0;
      position: relative;
      cursor: pointer;

      @media (max-width: 1400px) {
        padding: 24px 10px;
      }

      .img {
        position: relative;
        margin: 0 auto 24px;
        width: 100%;
        max-width: 130px;
        height: 130px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          width: 100%;
          max-width: 110px;

          &.hover {
            z-index: 2;
            opacity: 0;
          }
        }
      }

      .text {
        span {
          display: block;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
        }
      }

      &:before {
        content: '';
        display: block;
        border: 17px solid transparent;
        border-top: 17px solid $coral;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -17px;
        pointer-events: none;
        opacity: 0;
        transition: all 0.15s ease-in-out;
      }

      &:hover {
        background-color: $coral;
        .img {
          img {
            opacity: 0;
            &.hover {
              opacity: 1;
            }
          }
        }

        .text {
          span {
            color: #ffffff;
          }
        }
      }

      &.active {
        background-color: $coral;
        box-shadow: 0 0 0 0 rgba(0, 72, 135, 0.1);

        .img {
          img {
            opacity: 0;
            &.hover {
              opacity: 1;
            }
          }
        }

        .text {
          span {
            color: #ffffff;
          }
        }

        &:before {
          opacity: 1;
          bottom: -34px;
        }
      }
    }
  }

  .trek_content {
    .trek {
      display: none;

      &.active {
        display: block;
        animation: show_trek 0.15s 1 ease-in-out;
      }

      .text {
        p {
          margin: 0 0 16px 0;
        }
      }

      .category {
        & + .category {
          margin-top: 40px;
        }

        .treks_group {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -12px;
          position: relative;

          .trek_card {
            position: relative;
            border-radius: 8px;
            height: 200px;
            margin: 12px;
            width: calc(50% - 24px);
            overflow: hidden;
            cursor: pointer;

            .name {
              text-align: center;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              left: 40px;
              right: 40px;

              span {
                font-size: 24px;
                font-weight: 500;
                line-height: 1.17;
                color: #ffffff;
              }
            }

            .bg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: 50%;
              z-index: 1;
              transform: scale(1);
              border-radius: 8px;

              &:after {
                content: '';
                display: block;
                background-image: radial-gradient(circle at 50% 0, $french-blue, $light-indigo);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: all 0.15s ease-in-out;
              }
            }

            &:hover {
              .bg {
                &:after {
                  opacity: 0.6;
                }
              }
            }

            &.active {
              .bg {
                &:after {
                  opacity: 0.8;
                }
              }
            }
          }

          .more_info {
            z-index: 9;
            padding: 24px 10px 24px 24px;
            border-radius: 8px;
            box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
            background-color: #ffffff;
            height: 424px;
            position: absolute;
            top: 12px;
            bottom: 12px;
            right: 12px;
            width: calc(50% - 24px);
            display: none;
            animation: hide_trek 0.15s 1 ease-in-out;

            &:before {
              content: '';
              display: block;
              border: 17px solid transparent;
              border-right: 17px solid #ffffff;
              position: absolute;
              bottom: 83px;
              left: 0;
              margin-left: -34px;
              pointer-events: none;
              transition: all 0.15s ease-in-out;
            }

            &.left {
              right: initial;
              left: 12px;

              &:before {
                margin-left: 0;
                margin-right: -17px;
                right: 0;
                border-left: 17px solid #ffffff;
                border-right: transparent;
                left: initial;
              }
            }

            &.left.arrow_top,
            &.arrow_top {
              &:before {
                bottom: initial;
                top: 83px;
                margin-left: -34px;
              }
            }

            .scroll {
              height: 316px;
              overflow: auto;
              padding-right: 10px;

              &::-webkit-scrollbar {
                width: 4px;
                height: 10px;
              }

              &::-webkit-scrollbar-button:start:decrement,
              &::-webkit-scrollbar-button:end:increment {
                display: none;
              }

              &::-webkit-scrollbar-track-piece {
                background-color: none;
              }

              &::-webkit-scrollbar-thumb:vertical {
                border-radius: 3px;
                background-color: #cccccc;
              }

              .competences {
                margin: 24px 0;

                .competence {
                  padding: 0 0 0 40px;
                  position: relative;
                  min-height: 24px;

                  i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $french-blue;
                    display: block;
                    width: 24px;
                    height: 24px;
                    font-size: 24px;
                  }

                  span {
                    font-size: 14px;
                    line-height: 24px;
                    color: #000000;
                    display: block;
                  }

                  & + .competence {
                    margin-top: 16px;
                  }
                }
              }

              .item {
                padding-left: 160px;
                position: relative;
                font-size: 14px;
                margin-top: 24px;

                b {
                  position: absolute;
                  left: 0;
                  top: 0;
                  display: block;
                  line-height: 20px;
                }

                span {
                  width: 295px;
                  line-height: 20px;
                }

                & + .item {
                  margin-top: 12px;
                }
              }
            }

            .btn_block {
              padding: 16px 14px 16px 0;

              .blue_btn {
                width: 200px;
              }
            }

            &.active {
              display: block;
              animation: show_trek 0.15s 1 ease-in-out;
            }
          }
        }
      }

      .select_content_list {
        position: relative;
        margin-top: 40px;

        .select_content {
          height: 160px;
          position: relative;
          background: #333;
          border-radius: 8px;
          margin: 24px 0 0 0;

          span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 80px;
            right: 320px;
            font-size: 20px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: 0.2px;
            color: #ffffff;
          }

          .blue_btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 40px;
            width: 240px;
          }

          &.navigator {
            background: url('./img/navigator_bg.png') no-repeat center;
            background-size: cover;
          }

          &.education {
            background: url('./img/education_bg.png') no-repeat center;
            background-size: cover;
          }

          &.project {
            background: url('./img/project_bg.png') no-repeat center;
            background-size: cover;
          }

          &.events {
            background: url('./img/events_bg.png') no-repeat center;
            background-size: cover;
          }
        }
      }
    }

    .trek-field {
      .category {
        .text {
          margin-bottom: 40px;
        }

        .treks_group {
          .trek_card {
            .bg {
              background-image: url('./img/trek-field-image.jpg');
            }
          }
        }
      }
    }
  }
}
