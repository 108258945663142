// страницы, на которых есть справа блок профиля
.content-treks,
.content-profile,
.content-cource {
  width: 1352px;

  @media (max-width: 1900px) {
    width: calc(100% - 60px);
  }
}

.settings_page {
  width: 940px;
}
