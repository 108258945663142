.link_border {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
  font-weight: 400;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #bfbfbf;

  &.ico {
    padding-left: 28px;

    &:after {
      left: 28px;
    }
  }

  &.ico-attention:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../img/icons/a-attention.svg) no-repeat 50%;
  }
  &.ico-person-delete:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../img/icons/a-person-delete.svg) no-repeat 50%;
  }
  &.ico-create-chat:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../img/icons/a-create-chat.svg) no-repeat 50%;
  }

  &:hover {
    color: #1588ee;
    border-color: #1588ee;
  }
}
