.sign-board {
  padding-left: 28px;
  text-align: left;

  span {
    opacity: 0.25;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
  }

  &.user {
    background: url('../../img/icons/a-person.svg') no-repeat 0;
  }

  &.organization {
    background: url('../../img/icons/a-organization.svg') no-repeat 0;
  }

  &.video {
    background: url('../../img/icons/a-video.svg') no-repeat 0;
  }

  &.photo {
    background: url('../../img/icons/a-photo.svg') no-repeat 0;
  }

  &.date {
    background: url('../../img/icons/a-time.svg') no-repeat 0;
  }

  &.mail {
    background: url('../../img/icons/a-mail.svg') no-repeat 0;
  }

  &.phone {
    background: url('../../img/icons/a-phone.svg') no-repeat 0;
  }

  &.like {
    position: relative;
    cursor: pointer;

    &:after,
    span {
      transition: all 0.15s ease-in-out;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: url('../../img/icons/a-like.svg') no-repeat 0;
      opacity: 0.5;
    }

    &:hover:after {
      opacity: 1;
    }

    &.active {
      &:after {
        opacity: 1;
        background: url('../../img/icons/a-like-active.svg') no-repeat 0;
      }

      span {
        opacity: 0.75;
      }
    }
  }

  &.geo {
    background: url('../../img/icons/a-place.svg') no-repeat 0;
  }
}

.sign-boards-line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .sign-board {
    + .sign-board {
      margin: 0 !important;
    }

    &.a-card-education {
      background: url('../../img/icons/a-card-education.svg') no-repeat 0;
    }

    &.a-card-project {
      background: url('../../img/icons/a-card-project.svg') no-repeat 0;
    }

    &.a-card-events {
      background: url('../../img/icons/a-card-events.svg') no-repeat 0;
    }

    &.a-card-challenge {
      background: url('../../img/icons/a-card-challenge.svg') no-repeat 0;
    }
  }
}

.sign-board.status.ended {
  background-color: #bfbfbf;
}
