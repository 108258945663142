.page {
  padding-top: 80px;
  padding-left: 300px;
  padding-right: 25px;
  position: relative;
  overflow: hidden;
  @media (max-width: 1400px) {
    overflow: auto;
  }
  .blue_block_link {
    margin-top: 80px;
    border-radius: 8px;
    background: #f2f6fa;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translate(-50%);
      width: 1px;
      background: #fff;
    }

    > div {
      width: 50%;
      padding: 16px;

      span {
        display: block;
        font-weight: 700;
      }

      a {
        display: block;
        padding-left: 32px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;

        &.file {
          background: url(../../img/icons/a-file.svg) 0 no-repeat;
        }

        &.external_link {
          background: url(../../img/icons/a-link.svg) 0 no-repeat;
        }

        &.complete {
          background: url(../../img/icons/a-check_blue.svg) 0 no-repeat;
        }
      }
    }
  }

  &.col-8-content {
    .content {
      padding-left: 156px;
      position: relative;
      width: 100%;
      max-width: 988px;
    }

    &.center .content {
      padding-left: 24px;
      margin: 0 auto;
    }
  }

  &.right_block {
    padding-right: 340px;

    &.expand_right {
      padding-right: 115px;
    }
  }

  .content {
    padding: 24px;
  }

  & > .content {
    min-height: calc(100vh - 80px);
    @media (min-width: 2199px) {
      padding-left: 200px;
      padding-right: 200px;
    }
    @media (min-width: 2300px) {
      padding-left: 300px;
      padding-right: 300px;
    }
  }

  .left_bar_page {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .left_bar {
      padding: 0;
      width: 320px;

      .page_name {
        padding-bottom: 32px;

        span {
          font-size: 32px;
          font-weight: 500;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.24px;
          color: #000;
        }
      }

      .filtr_title {
        padding-left: 24px;
        padding-bottom: 24px;

        span {
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.15px;
          color: #000;
        }
      }

      .clear_filtr {
        background: url(../../img/icons/a-close.svg) no-repeat 0;
        padding-left: 32px;
        cursor: pointer;

        span {
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          display: block;
        }
      }
    }

    .content {
      padding-top: 0;
      padding-left: 24px;
      width: calc(100% - 320px);
    }
  }

  .content_8col {
    max-width: 744px;
    width: 100%;
    margin: 0 auto;

    .page_title {
      margin-bottom: 40px;
    }

    img {
      margin: 80px 0 !important;
    }

    h2 {
      line-height: 28px;
      font-size: 24px;
      margin-bottom: 24px;
      margin-top: 40px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 24px;
      margin-top: 40px;
    }

    ol,
    ul {
      margin: 24px 0 40px;
      padding-left: 25px;
    }

    ol li,
    ul li {
      padding-left: 15px;
    }

    ol li + li,
    ul li + li {
      margin-top: 10px;
    }

    iframe {
      margin: 80px -98px;
      width: calc(100% + 196px);
      height: 528px;
    }
  }

  > .content_8col {
    max-width: 792px;
    padding: 40px 24px 80px;

    .content {
      padding: 0;
    }
  }

  .nav_blue {
    background: #4167bb;
    height: 80px;
    padding: 28px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;

    .left,
    .right {
      position: absolute;
    }

    .left a,
    .right a {
      display: block;
      line-height: 24px;
      color: #fff;
      position: relative;
      font-weight: 300;
      font-size: 16px;
    }

    .left a {
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.15s ease-in-out;
      }
    }

    .right a {
      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.15s ease-in-out;
      }
    }

    .left a:before,
    .right a:before {
      bottom: 0;
      width: calc(100% - 30px);
      height: 1px;
      background: #fff;
      opacity: 0.25;
    }

    .left a:after,
    .right a:after {
      top: 0;
      bottom: 0;
      width: 24px;
      background: url(../../img/icons/a-arrow_right-button.svg) no-repeat 50%;
      background-size: contain;
      opacity: 0.5;
    }

    .left a:hover:before,
    .right a:hover:before {
      opacity: 0.5;
    }

    .left a:hover:after,
    .right a:hover:after {
      opacity: 1;
    }

    .left {
      left: 15px;

      a {
        padding-left: 30px;

        &:before {
          right: 0;
        }

        &:after {
          left: 0;
          transform: rotate(180deg);
        }
      }
    }

    .content_8col {
      margin-left: 180px;
      margin-right: 180px;

      .center .drop_down_list .selected span {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #fff;
      }
    }

    .right {
      right: 15px;

      a {
        padding-right: 30px;

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 1650px) {
  .page {
    padding-left: 98px;
  }
}

@media (max-width: 1650px) {
  .page.right_block {
    padding-right: 95px;
  }
}

.page {
  @media (max-width: 1667px) {
    padding-left: 120px;
  }

  @media (max-width: 1650px) {
    &.right_block {
      padding-right: 95px;
    }
  }

  @media (max-width: 1400px) {
    overflow: auto;
  }

  &__container {
    position: relative;
    padding: 24px 0 48px;
    min-height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
  }

  &__content {
    position: relative;
    padding-left: 24px;
    width: 1032px;

    @media (max-width: 1490px) {
      width: 688px;
    }
  }

  &__content-full {
    max-width: 1400px;
    width: 100%;

    .row {
      margin-left: 0;
      margin-right: 0;

      .col-8 {
        padding-left: 0;

        .row {
          .col-4 {
            padding: 0 15px 0 0;
          }
        }
      }
      .col-4 {
        padding-right: 0;
      }
      .col-10 {
        padding: 0;
      }
      .col-6 {
        padding: 0 30px 0 0;
      }
    }
  }

  &__content-min {
    width: 664px;
  }

  &__content-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  // для страницы события event_page
  &_event,
  &_navigator-page,
  &_track {
    @media (max-width: 1500px) {
      padding: 80px 15px 0 110px;

      .page__container {
        justify-content: flex-start;
      }
    }

    @media (max-width: 1440px) {
      .activity-card {
        width: 303px;
      }
    }
  }

  &_navigator-page {
    .activityContainer {
      .col-12 {
        padding: 0;
      }
    }
  }

  &_works {
    .showcase_title {
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      color: #000;
    }
  }
}
