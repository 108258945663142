.modal-competencies {
  .switch_content {
    padding: 0 24px 24px 24px;
  }

  .modal_tabs {
    background: #f2f6fa;
    padding: 0 24px;
  }

  .btn_line {
    display: flex;
    padding: 24px;
    background: #f2f6fa;
  }

  .competence_points_card,
  .competences_list {
    display: flex;
    flex-wrap: wrap;

    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
