.filter-form {
  &__title {
    padding-bottom: 24px;

    span {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      color: #000;
    }
  }

  &__clear {
    background: url(../../old/img/icons/a-close.svg) no-repeat 0;
    padding-left: 32px;
    cursor: pointer;

    span {
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
      display: block;
    }
  }

  .MuiInputBase-root {
    width: 100%;
    border-bottom: none !important;
    margin-bottom: 24px;
  }

  .MuiSelect-root {
    border-radius: 22px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.5;
    color: #000;
  }

  .MuiSvgIcon-root {
    background: url(./a-arrow_down.svg);
    color: transparent;
    right: 12px;
  }

  option {
    font: 14px Helvetica, Arial;
    user-select: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    text-indent: 5px; /* Horizontal Positioning of the select box option text */
    margin: 0;
    list-style-type: none;
  }
}

.MuiPopover-root {
  .MuiPaper-root {
    border-radius: 22px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    // box-shadow: none;
  }

  .MuiList-root {
    border-radius: 22px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.5;
    color: #000;
  }
}
