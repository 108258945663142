.allert_list {
  z-index: 11;

  .allert {
    width: 392px;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
    background: #333;
    color: #fff;
    cursor: pointer;
    transition: none;
    overflow: hidden;

    &.green {
      background: #00cd6a;
    }

    &.yellow {
      background: #ffc500;
    }

    &.red {
      background: #ff4b3a;
    }

    + .allert {
      margin-top: 8px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 16px;
      right: 16px;
      display: block;
      width: 24px;
      height: 24px;
      background: url(../../old/img/icons/a-close-white.svg);
      opacity: 0.75;
    }

    &:hover:before {
      opacity: 1;
    }

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      display: block;
      margin-bottom: 4px;
      padding: 16px;
      padding-bottom: 0;
    }

    p {
      margin: 0;
      opacity: 0.75;
      font-size: 14px;
      padding: 16px;
      padding-top: 0;
    }
  }
}

.allert {
  overflow: hidden;
  position: fixed;
  top: 104px;
  right: 25px;
  z-index: 10;
  width: 305px;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgb(0 72 135 / 10%);
  font-family: Roboto;
  font-size: 14px;
  color: #fff;

  span {
    font-size: 16px;
  }

  p {
    opacity: 0.75;
  }
}
