.certificate {
  margin-top: 80px;
  display: flex;

  &__text {
    width: 45%;
  }

  &__image {
    position: relative;
    margin-left: 8%;
    width: 375px;

    img {
      display: block;
      width: 100%;
    }
  }
}
