.detail-qualification {
  &__content {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 30px;
    margin-right: 30px;
  }

  &__cards-about {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }

  &__card {
    padding: 16px;
    position: relative;
    border-radius: 8px;
    background-color: #f2f6fa;
    height: 100px;
    border: 0;

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      width: 50px;
      height: 50px;

      img {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      position: absolute;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.15px;
      color: #4167bb;
      padding: 16px 16px 16px 66px;
      top: 50%;
      transform: translateY(-50%);

      span {
        font-size: 20px;
        display: block;

        + span {
          margin-top: 4px;
          font-size: 14px;
        }
      }
    }
  }

  &__text-goal {
    width: 60%;
  }

  &__competences-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__competence-track {
    margin-bottom: 20px;
  }

  &__activity {
    width: 1210px;
    background: url(./img/path.svg) repeat-y 50% 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    font-family: Roboto;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  &__activity-item {
    height: 316px;
    width: 232px;
    margin-bottom: 60px;
    padding: 16px;
    background: #fff;
  }

  &__activity-bg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #333;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__activity-content {
    position: absolute;
    top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__activity-status {
    padding: 2px 16px;
    border-radius: 12px;
    color: #fff;
    width: min-content;
    font-size: 14px;
    line-height: 1.43;
    min-height: 24px;
  }

  &__activity-tag-line {
    opacity: 0.75;
    font-size: 12px;
    line-height: 1.67;
    color: #7563c7;
    margin-top: 12px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &__activity-text {
    width: 240px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: center;
    color: #000;
  }
}
