.modal-activity {
  display: block;
  opacity: 1;
  z-index: 9999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  cursor: pointer;
  transition: none;
  overflow: auto;

  &__popup {
    width: 1056px;
    border-radius: 8px;
    background: #fff;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99999;
    padding: 0;
    transition: none;
    margin-bottom: 30px;
    cursor: default;

    &_very-min {
      width: 552px;
    }

    &_min {
      width: 798px;
    }

    &_big {
      width: 1240px;
    }

    &.grey {
      .modal-activity__title {
        background: #f2f6fa;
      }
    }
  }

  &__title {
    padding: 22px 24px;

    h3 {
      margin: 0;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    display: block;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #000;
    opacity: 0.25;

    &:hover {
      opacity: 0.5;
    }
  }
}
