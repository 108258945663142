.content-modal {
  &__skills {
    max-height: 240px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__skill {
    margin: 8px 16px 8px 0;
    border: 1px solid #000;
    padding: 0 16px;
    display: block;

    line-height: 30px;
    height: 32px;
    font-weight: 400;
    border-radius: 20px;
    border-color: #f2f2f2;
    opacity: 1;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    position: relative;
    cursor: pointer;

    &.active,
    &:hover {
      background: #f2f6fa;
      color: #000;
    }

    &_edit {
      padding: 0 36px 0 16px;

      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url(../../../old/img/icons/a-delete_ico.svg) no-repeat 50%;
        background-size: contain;
        position: absolute;
        right: 4px;
        top: 3px;
      }
    }
  }

  &__skills-all {
    flex-grow: 1;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    margin-top: 24px;
  }
}
