#geoLocation_modal {
  display: block;
  opacity: 1;
}

.geo-regions {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__item {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
    cursor: pointer;

    &:hover {
      color: #1588ee;
    }
  }
}
