.traectoria_line {
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  .traectories {
    position: relative;
    height: 290px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 24px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      top: 0;
      bottom: 0;
      z-index: 2;
      right: 0;
    }

    &:before {
      right: initial;
      left: 0;
      transform: rotate(180deg);
    }

    .traectories_scroll_line {
      display: flex;
      min-width: 100%;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 20px;
        background: #e6e6e6;
        top: calc(50% - 10px);
      }

      .traectoria_section {
        min-width: 183px;
        width: calc((100vw - 280px - 320px - 24px * 2) / 5);
        position: relative;
        z-index: 1;
        height: 20px;
        top: 50%;
        margin-top: -10px;

        @media (max-width: 1650px) {
          width: calc((100vw - 98px - 95px - 24px * 2) / 5);
        }
        @media (min-width: 2199px) {
          width: calc((100vw - 280px - 320px - 200px * 2) / 5);
        }
        @media (min-width: 2300px) {
          width: calc((100vw - 280px - 320px - 300px * 2) / 5);
        }

        &.education {
          .traectoria_content {
            .icon {
              &:before,
              &:after {
                background: #1588ee;
              }

              & > div,
              & > a {
                border-color: #1588ee;
                &:before {
                  background: #1588ee;
                  opacity: 0.1;
                }
                span {
                  color: #1588ee;
                }
              }
            }
          }

          .activity {
            background: #1588ee;
          }

          &.performed {
            .traectoria_content {
              .icon {
                & > div,
                & > a {
                  background: #1588ee;
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }

          & + .traectoria_section {
            .activity {
              &:before {
                background: #1588ee;
              }
            }
          }
        }

        &.event {
          .traectoria_content {
            .icon {
              &:before,
              &:after {
                background: #00cd6a;
              }

              & > div,
              & > a {
                border-color: #00cd6a;
                &:before {
                  background: #00cd6a;
                  opacity: 0.1;
                }
                span {
                  color: #00cd6a;
                }
              }
            }
          }

          .activity {
            background: #00cd6a;
          }

          &.performed {
            .traectoria_content {
              .icon {
                & > div,
                & > a {
                  background: #00cd6a;
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }

          & + .traectoria_section {
            .activity {
              &:before {
                background: #00cd6a;
              }
            }
          }
        }

        &.challenge {
          .traectoria_content {
            .icon {
              &:before,
              &:after {
                background: #ff800b;
              }

              & > div,
              & > a {
                border-color: #ff800b;
                &:before {
                  background: #ff800b;
                  opacity: 0.1;
                }
                span {
                  color: #ff800b;
                }
              }
            }
          }

          .activity {
            background: #ff800b;
          }

          &.performed {
            .traectoria_content {
              .icon {
                & > div,
                & > a {
                  background: #ff800b;
                  span {
                    color: #fff;
                  }
                }
              }

              .text {
                .status {
                  color: #7563c7;
                }
              }
            }
          }
          & + .traectoria_section {
            .activity {
              &:before {
                background: #ff800b;
              }
            }
          }
        }

        &.project {
          .traectoria_content {
            .icon {
              &:before,
              &:after {
                background: #ffc500;
              }

              & > div,
              & > a {
                border-color: #ffc500;

                &:before {
                  background: #ffc500;
                  opacity: 0.1;
                }

                span {
                  color: #ffc500;
                }
              }
            }
          }

          .activity {
            background: #ffc500;
          }

          &.performed {
            .traectoria_content {
              .icon {
                & > div,
                & > a {
                  background: #ffc500;
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }

          & + .traectoria_section {
            .activity {
              &:before {
                background: #ffc500;
              }
            }
          }
        }

        &:first-child {
          margin-left: 0;
          .activity {
            &:before {
              display: none;
            }
          }
        }

        &:nth-child(2n) {
          .traectoria_content {
            bottom: 20px;
            top: initial;

            .icon {
              top: initial;
              bottom: 0;

              &:before {
                display: none;
              }

              &:after {
                display: block;
              }
            }

            .text {
              margin-top: 0;
              margin-bottom: 20px;
            }
          }
          .activity {
          }
        }

        &:nth-last-child(-n + 2) {
          .traectoria_content {
            width: calc(((100vw - 280px - 320px - 24px * 2) / 5) * 1.5 - 0px);

            @media (max-width: 1650px) {
              width: calc(((100vw - 98px * 2 - 24px * 2) / 5) * 1.5 - 0px);
            }
            @media (min-width: 2199px) {
              width: calc(((100vw - 280px - 320px - 200px * 2) / 5) * 1.5 - 0px);
            }
            @media (min-width: 2300px) {
              width: calc(((100vw - 280px - 320px - 300px * 2) / 5) * 1.5 - 0px);
            }
          }
        }

        .traectoria_content {
          min-width: 326px;
          position: absolute;
          top: 20px;
          left: calc(50% - 16px);
          padding-left: 56px;
          min-height: 96px;
          width: calc(((100vw - 280px - 320px - 24px * 2) / 5) * 2 - 40px);

          @media (max-width: 1650px) {
            width: calc(((100vw - 98px * 2 - 24px * 2) / 5) * 2 - 40px);
          }
          @media (min-width: 2199px) {
            width: calc(((100vw - 280px - 320px - 200px * 2) / 5) * 2 - 40px);
          }
          @media (min-width: 2300px) {
            width: calc(((100vw - 280px - 320px - 300px * 2) / 5) * 2 - 40px);
          }

          .icon {
            position: absolute;
            left: 0;
            top: 0;
            height: 96px;
            width: 32px;

            &:before,
            &:after {
              content: '';
              display: block;
              width: 2px;
              height: 64px;
              margin: 0 auto;
              background: #e6e6e6;
            }

            &:after {
              display: none;
            }

            & > div,
            & > a {
              display: block;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              border: solid 2px #e6e6e6;
              text-align: center;
              padding: 2px;
              position: relative;

              &:before {
                content: '';
                display: block;
                width: 48px;
                height: 48px;
                background: #e6e6e6;
                opacity: 0.25;
                position: absolute;
                top: -10px;
                left: -10px;
                border-radius: 50%;
                z-index: 0;
                transition: all 0.15s ease-in-out;
              }

              span {
                font-size: 16px;
                display: block;
                height: 24px;
                line-height: 24px;
                color: #e6e6e6;
                position: relative;
                z-index: 1;
              }
            }
          }

          .text {
            margin-top: 20px;

            .status {
              font-size: 12px;
              font-weight: bold;
              color: #bfbfbf;
              line-height: 20px;
              text-transform: uppercase;
              display: block;

              & > span {
                display: inline-block;
                margin-left: 8px;
                padding: 0 8px;
                border-radius: 10px;
                background: #7563c7;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                text-transform: initial;
              }
            }

            .name {
              display: block;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.43;
              color: #000000;

              &:hover {
                color: #1588ee;
              }
            }

            .line {
              display: flex;
              flex-wrap: wrap;
              margin: 8px -4px 0;

              .track-prog {
                margin: 2px 4px;
                padding: 3px 8px;
                border-radius: 12px;
                border: solid 1px #7563c7;
                display: flex;
                flex-wrap: wrap;
                height: 24px;

                .ico {
                  width: 16px;
                  height: 16px;
                  position: relative;

                  img,
                  svg {
                    max-width: 16px;
                    max-height: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    path {
                      fill: #7563c7;
                    }
                  }
                }

                span {
                  display: block;
                  padding-left: 4px;
                  line-height: 16px;
                  font-size: 12px;
                  font-weight: bold;
                  color: #7563c7;
                }

                &:hover {
                  background: #7563c7;

                  .ico {
                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }

                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
          &:hover {
            .icon {
              & > div,
              & > a {
                &:before {
                  width: 28px;
                  height: 28px;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
        }

        .activity {
          width: 100%;
          height: 20px;
          color: #fff;
          background: #e6e6e6;
          position: relative;
          overflow: hidden;

          &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            transform: translate(-50%, -50%) rotate(45deg);
            left: 0;
            top: 50%;
          }

          span {
            display: block;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}

.slider-trajectory {
  width: 100%;

  .slick-slide {
    height: 300px;
  }

  .slick-arrow {
    top: 125px;
    z-index: 3;
  }

  .slick-prev {
    right: 1360px;
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-list {
    .slick-track {
      .slick-slide {
        &:nth-child(2n) {
          .traectoria_content {
            bottom: 20px;
            top: initial;

            .icon {
              top: initial;
              bottom: 0;

              &:before {
                display: none;
              }

              &:after {
                display: block;
              }
            }

            .text {
              margin-top: 0;
              margin-bottom: 20px;
            }
          }
        }

        .traectoria_section {
          margin-top: 135px;

          .activity {
            .traectoria-arrow {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
              position: absolute;
              transform: translate(-50%, -50%) rotate(45deg);
              left: 0;
              top: 50%;

              &.traectoria-arrow__no-arrow {
                border: 0;
              }

              &.course {
                background: #1588ee;
              }
              &.event {
                background: #00cd6a;
              }
              &.challenge {
                background: #ff800b;
              }
              &.project {
                background: #ffc500;
              }
            }
          }
        }
      }
    }
  }
}
