.activity-card {
  width: 320px;
  height: 396px;
  border-radius: 8px;
  border: 1px solid rgba(191, 191, 191, 0.25);
  background-color: #fff;
  position: relative;
  overflow: hidden;

  &:hover {
    border: 1px solid rgba(191, 191, 191, 0.5);
  }

  &__img-block {
    height: 200px;
    position: relative;
  }

  &__members {
    padding-left: 28px;
    height: 24px;
    line-height: 24px;
    background: url(../../old/img/icons/icons-a-person.svg) no-repeat 0;
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 14px;
    font-weight: 400;
    z-index: 1;
    color: #fff;
  }

  &__price {
    position: absolute;
    right: 0;
    top: 16px;
    padding: 0 16px;
    border-radius: 12px 0 0 12px;
    background-color: #1588ee;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    z-index: 1;
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    min-height: calc(100% - 200px);
  }

  &__tag-line {
    opacity: 0.75;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #7563c7;

    span {
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;

      + span {
        margin-left: 16px;
      }
    }
  }

  &__caption {
    margin: 12px 0 20px 0;
    line-height: 24px;
    white-space: normal;
  }

  &__sign-board {
    margin: 4px 0;
    padding-left: 28px;
    text-align: left;

    span {
      opacity: 0.25;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #000;
    }

    &_user {
      background: url(../../old/img/icons/a-person.svg) no-repeat 0;
    }

    &_organization {
      background: url(../../old/img/icons/icons-a-organization.svg) no-repeat 0;
    }

    &_video {
      background: url(../../old/img/icons/a-video.svg) no-repeat 0;
    }

    &_photo {
      background: url(../../old/img/icons/a-photo.svg) no-repeat 0;
    }

    &_date {
      background: url(../../old/img/icons/icons-a-time.svg) no-repeat 0;
    }

    &_mail {
      background: url(../../old/img/icons/a-mail.svg) no-repeat 0;
    }

    &_phone {
      background: url(../../old/img/icons/a-phone.svg) no-repeat 0;
    }

    &.like {
      position: relative;
      cursor: pointer;

      &:after,
      span {
        transition: all 0.15s ease-in-out;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: url(../../old/img/icons/a-like.svg) no-repeat 0;
        opacity: 0.5;
      }

      &:hover:after {
        opacity: 1;
      }

      &.active {
        &:after {
          opacity: 1;
          background: url(../../old/img/icons/a-like-active.svg) no-repeat 0;
        }

        span {
          opacity: 0.75;
        }
      }
    }

    &_geo {
      background: url(../../old/img/icons/icons-a-place.svg) no-repeat 0;
    }
  }

  &__status {
    position: absolute;
    right: 0;
    top: 16px;
    padding: 0 16px;
    border-radius: 12px 0 0 12px;
    background-color: #7563c7;

    &.gray,
    &.close {
      background-color: #bfbfbf;
    }

    &.orange {
      background-color: #ff800b;
    }

    &.green {
      background-color: #00cd6a;
    }

    &.turquoise {
      background-color: #2ed0d3;
    }

    span {
      opacity: 1;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
    }
  }

  /* ниже для прелоадера */

  &__preload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    animation: c 0.3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    background: #f2f2f2;

    &:before {
      content: '';
      display: block;
      background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff 50%, hsla(0, 0%, 100%, 0));
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      background-size: 200% 200%;
      animation: d 1s infinite;
    }
  }

  &__img-block_preload {
    background: #f2f2f2;
  }

  &__members_preload {
    z-index: 10;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 20px;
      position: absolute;
      background: #fff;
    }

    &:before {
      width: 20px;
      left: 0;
      margin-right: 8px;
      border-radius: 50%;
    }

    &:after {
      width: 40px;
      left: 22px;
      border-radius: 10px;
    }
  }

  &__price_preload {
    width: 100px;
    height: 24px;
    background: #f5f5f5;
    z-index: 10;
  }

  &__content_preload {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
  }

  &__tag-line_preload {
    padding-bottom: 8px;

    span {
      width: 120px;
      height: 16px;
      border-radius: 8px;
      background: #f2f2f2;
      opacity: 0.75;
      font-family: Roboto;
      font-size: 12px;
      line-height: 20px;
      color: #7563c7;
    }
  }

  &__caption_preload {
    width: 200px;
    height: 24px;
    border-radius: 12px;
    background: #f2f2f2;
    margin: 2px 0;
  }

  &__sign-board_preload {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    background: none;
    margin: 10px 0;

    &:before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #f2f2f2;
      margin-right: 4px;
    }

    span {
      display: block;
      width: 120px;
      height: 20px;
      border-radius: 8px;
      background: #f2f2f2;
      opacity: 1;
    }
  }

  &__status_preload {
    width: 100px;
    height: 24px;
    background: #f2f2f2;
  }
}
