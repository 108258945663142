.msgs_page {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: calc(100vh - 80px);
  max-width: 760px;
  margin: 0 auto;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;

  .list_of_messages {
    padding: 24px 24px 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .page_name {
      padding-bottom: 24px;

      span {
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.17;
        color: #000;
      }
    }

    .header {
      background-color: #fff;
      position: relative;
      margin-bottom: 24px;

      .name {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: 0.15px;
        color: #000;
      }

      .title_menu {
        position: absolute;
        height: 24px;
        top: 2px;
        right: -10px;
      }

      .title_menu_content {
        top: 37px;
        right: -19px;
      }
    }

    .find_dialog {
      padding-left: 24px;
      padding-bottom: 24px;

      span {
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.15px;
        color: #000;
      }
    }

    .dialogs_list {
      margin: 0 -24px;
      position: relative;
      max-height: calc(100% - 123px);
      overflow: auto;
      padding: 0;
      border-top: 1px solid #f2f2f2;

      .dialog {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        height: 80px;
        padding: 12px 24px;
        border: 0 !important;
        opacity: 1 !important;
        position: relative;

        &:hover {
          background-color: #f2f6fa;
          &:after {
            left: 0;
          }
        }

        &.new {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 24px;
            width: 9px;
            height: 9px;
            background-color: #1588ee;
            border-radius: 50%;
            transform: translateY(-50%);
          }

          .text {
            padding-right: 32px;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 95px;
          height: 1px;
          background: #f2f2f2;
        }

        + .dialog {
          margin-left: 0;
        }

        .ava {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: #333;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50%;
        }

        .info {
          padding-left: 16px;
          width: calc(100% - 56px);
          position: relative;

          .text {
            text-align: left;
            position: absolute;
            top: 50%;
            left: 16px;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            flex-wrap: wrap;

            span {
              display: block;
              color: #000;
            }

            .name {
              font-weight: 400;
              font-size: 16px;
              line-height: 1.5;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 210px;
            }

            .role {
              font-weight: 500;
              opacity: 0.25;
              font-size: 14px;
              line-height: 1.43;
            }

            .msg_text {
              width: calc(100% - 210px);
              padding-left: 24px;
              display: flex;

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #bfbfbf;

                &.time {
                  text-overflow: initial;
                  overflow: visible;
                  display: block;
                  padding-left: 6px;
                }
              }
            }
          }
        }
      }
    }

    .clead_history {
      .text {
        width: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          display: block;
          margin: 0 auto;
          width: 80px;
          height: 80px;
        }

        span {
          display: block;
          margin-top: 16px;
          opacity: 0.25;
          font-size: 14px;
          line-height: 1.43;
          color: #000000;
        }
      }
    }
  }

  .open_dialog {
    .header {
      padding: 16px 16px 12px 24px;
      background-color: #fff;
      border-bottom: 1px solid #f2f2f2;
      position: relative;
      height: 76px;

      .back {
        position: absolute;
        left: 24px;
        top: 50%;
        margin-top: -12px;
        display: flex;

        img {
          display: block;
        }

        span {
          display: block;
          line-height: 24px;
          opacity: 0.25;
          color: #000;
        }

        &:hover {
          span {
            opacity: 0.5;
          }
        }
      }

      .name {
        color: #000;
        text-align: center;

        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.15px;
        }

        span {
          display: block;
          margin-top: 4px;
          opacity: 0.25;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: normal;
          color: #000000;
        }
      }

      .title_menu {
        position: absolute;
        height: 24px;
        top: 26px;
        right: 15px;
      }

      .title_menu_content {
        top: 65px;
        right: 6px;
      }
    }

    .msg_list {
      padding: 12px 24px;
      height: calc(100% - 273px);
      overflow: auto;
      background: #f2f6fa;

      .day {
        padding: 0 0 10px 0;

        & > *:last-child {
          margin-bottom: 30px;
        }

        .date {
          position: sticky;
          top: 0;
          font-size: 14px;
          color: #bfbfbf;
          border-radius: 12px;
          line-height: 24px;
          padding: 0 8px;
          display: block;
          margin: 0 auto;
          width: 90px;
          z-index: 1;
          background: #f2f6fa;

          & + .msgs {
            margin-top: 20px;
          }
        }
      }

      .chat_alert {
        margin: 22px 0;
        text-align: center;

        span {
          font-size: 14px;
          line-height: 20px;
          color: #bfbfbf;

          a {
            font-size: 14px;
            line-height: 20px;
            color: #bfbfbf;
            font-weight: 500;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .msgs {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 100%;
        padding: 0 0 0 72px;
        position: relative;

        + .msgs {
          margin-top: 24px;
        }

        .list {
          min-height: 56px;
          text-align: right;

          .msg {
            padding: 8px 16px 30px 16px;
            background-color: rgba(117, 99, 199, 0.25);
            border-radius: 20px;
            display: inline-block;
            position: relative;
            min-width: 90px;

            .name {
              margin-bottom: 4px;

              a {
                font-size: 14px;
                color: #7563c7;

                &:hover {
                  opacity: 0.8;
                }
              }
            }

            p {
              margin: 0;

              + p {
                margin-top: 8px;
              }

              a {
                font-weight: 400;
              }
            }

            .time {
              position: absolute;
              bottom: 8px;
              right: 16px;
              padding-right: 24px;

              &:before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                background: url('./img/a-message-sent.svg') no-repeat center;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
              }

              span {
                color: #000000;
                opacity: 0.25;
                font-size: 14px;
                line-height: 20px;
                display: block;
              }
            }

            &.reed {
              .time {
                &:before {
                  background: url('./img/a-message-read.svg') no-repeat center;
                }
              }
            }

            &:last-child {
              border-radius: 20px 20px 4px 20px;
            }
          }
        }

        .break {
          width: 100%;
          height: 8px;
        }

        .ava {
          right: 0;
          bottom: 0;
          position: absolute;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #333;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50%;
        }

        &.re {
          -ms-flex-pack: start;
          justify-content: flex-start;
          padding: 0 72px;

          .list {
            text-align: left;

            .msg {
              background-color: #fff;

              .time {
                padding-right: 0;
                background: none !important;

                &:before {
                  display: none;
                }
              }

              &:last-child {
                border-radius: 20px 20px 20px 4px;
              }
            }
          }

          .ava {
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    .send_msg_form {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      padding: 24px;
      border-top: 1px solid #f2f2f2;
      z-index: 1;

      textarea {
        height: 88px;
        margin-bottom: 24px;
      }

      .dialog_closed {
        position: relative;
        height: 148px;

        .text {
          text-align: center;
          width: 380px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          img {
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 auto;
          }

          span {
            display: block;
            margin-top: 16px;
            opacity: 0.25;
            font-size: 14px;
            line-height: 1.43;
            color: #000000;
          }
        }
      }
    }
  }

  .msg_content {
    background-color: #f2f6fa;
    width: 760px;
    border-right: 1px solid #f2f2f2;

    .tabs__content {
      height: calc(100vh - 80px);
      overflow: hidden;
      position: relative;

      &.prev .text {
        width: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
        }

        span {
          margin-top: 16px;
          display: block;
          opacity: 0.25;
          font-size: 14px;
          line-height: 1.43;
          text-align: center;
          color: #000;
        }
      }
    }
  }
}

.title_menu_content {
  position: absolute;
  right: -9px;
  top: 35px;
  padding: 6px 0;
  z-index: 5;
  box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.15);
  border-radius: 8px;
  background: #fff;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &:before {
    content: '';
    position: absolute;
    right: 10px;
    top: -22px;
    border: 11px solid transparent;
    border-bottom: 11px solid #fff;
  }

  a {
    display: flex;
    color: #000;
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 400;

    span {
      line-height: 24px;
      padding: 0 0 0 12px;
      white-space: nowrap;
    }

    &:hover {
      background: #f2f6fa;
    }

    &.red {
      color: #dc3545;

      &:hover {
        background: rgba(220, 53, 69, 0.1);
      }
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
