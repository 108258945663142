.create-chat {
  display: block;
  margin-top: 16px;

  &__title {
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 24px;
    color: inherit;
  }

  input {
    font-family: inherit;
  }

  .add_challenge {
    .line {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 28px;

      .user:first-child {
        .del {
          display: none;
        }
      }
    }
  }
}

.new_ava {
  position: relative;
  width: 80px;

  .upload_list {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    margin: 0;
    z-index: 1;
    span {
      display: block;
      width: 100%;
      height: 100%;

      .img-thumbnail {
        margin: 0;
      }
    }
  }

  .current_ava,
  .upload_list span .img-thumbnail {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    // background: #333;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;
  }

  .current_ava:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    border: 2px dashed #000;
    border-radius: 50%;
  }

  .add_new_ava_input {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;

    label {
      position: relative;
      z-index: 1;
      display: block;
      width: 32px;
      height: 32px;
      background: url(../../../old/img/icons/a-photo_change.svg) no-repeat 0;
      opacity: 1;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }

    input {
      position: absolute;
      z-index: 0;
      opacity: 0;
      display: block;
      border: 0;
      padding: 0;
      margin: 0;
      width: 0;
      height: 0;
      pointer-events: none;
    }
  }
}

.search_users {
  margin-top: 24px;
  border-top: 1px solid #f2f2f2;
  padding-top: 24px;

  &.show {
    display: block;
  }
  .find_form {
    margin-bottom: 24px;
    .input {
      margin-top: 0;
    }
  }

  .prev {
    position: relative;
    height: 340px;
    border-radius: 24px;
    background-color: #f2f6fa;
    .text {
      width: 280px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        display: block;
        margin: 0 auto;
      }
      span {
        display: block;
        margin-top: 16px;
        opacity: 0.25;
        font-size: 14px;
        line-height: 1.43;
        color: #000000;
      }
    }
  }
}
