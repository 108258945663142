.course-programm {
  &__table {
    width: 66%;

    .table-row,
    .table-head {
      .row {
        display: grid;
        grid-template-columns: 10fr 2fr;
      }
    }
  }
}
