.page_answer {
}

.work-answer {
  font-family: 'Roboto';

  &__back {
    margin-bottom: 12px;
  }

  &__column {
    display: grid;
    grid-template-columns: 75% 25%;
    column-gap: 30px;
  }

  &__name-like {
    display: flex;
    justify-content: space-between;
  }

  &__slide {
    height: 100%;
    min-height: 576px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__attachment {
    .page_title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__attachment-file {
    background: url(../../old/img/icons/a-file.svg) no-repeat 0 0;
    padding-left: 32px;
    font-weight: 400;
    min-height: 24px;
    display: inline-block;
    margin-right: 20px;
  }

  .slider-work {
    .slick-arrow {
      top: 50%;
      right: 20px;
      z-index: 15;
    }

    .slick-prev {
      left: 20px;
    }
  }

  .video_info {
    position: relative;
    height: 100%;

    .user_info {
      display: flex;
      flex-wrap: wrap;

      .ava {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #333;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
      }

      .text_block {
        width: calc(100% - 56px);
        padding-left: 16px;
        position: relative;

        .name {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          color: #000;
          margin-right: 32px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 32px);

          a > b {
            color: black;
            font-weight: 600;
          }
        }

        .date {
          margin-top: 4px;
          opacity: 0.25;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.43;
          color: #000;
        }
      }
    }

    .text {
      margin-top: 24px;
      height: 264px; // ?
    }
  }
}

.add_comment {
  width: 100%;

  .people_list {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 11.11111%;
      text-align: center;
    }
  }
}

.form-comment {
  border: 0;
  box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
  padding: 24px;

  &__form {
    display: flex;
    flex-wrap: wrap;

    .ava {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
    }

    .form {
      width: calc(100% - 56px);
      padding-left: 24px;
    }

    form .input {
      margin-bottom: 0;
    }

    .btn_block {
      margin-top: 24px;
      padding-bottom: 0;

      .blue_btn {
        width: auto;
        min-width: auto;
        margin-left: 24px;
      }

      & * {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  &__text-block {
    margin-top: 5px;
    margin-bottom: 0;

    textarea {
      font-family: inherit;
    }
  }

  &__text-label {
    position: absolute;
    padding: 4px 25px;
    opacity: 0.25;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 500;
    display: block;
  }
}

.comments .comment {
  display: flex;
  flex-wrap: wrap;

  .ava {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .comment_content {
    width: calc(100% - 56px);
    padding-left: 24px;

    .autor {
      display: flex;

      b {
        display: block;
        line-height: 24px;
        font-size: 16px;
        a {
          color: #000000;
          &:hover {
            color: #1588ee;
          }
        }
      }

      span {
        display: block;
        margin-left: 16px;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.25;
      }
    }

    .text p {
      margin: 8px 0;
    }

    .answer {
      padding-top: 8px;

      span {
        font-size: 16px;
        font-weight: bold;
        color: #bfbfbf;
        line-height: 1.5;
        cursor: pointer;

        &:hover {
          color: #1588ee;
        }
      }
    }
  }

  + {
    .comment {
      margin-top: 24px;
    }

    hr {
      margin: 24px 0;
    }
  }

  .sub-comments {
    padding-left: 80px;
    width: 100%;

    hr {
      margin: 24px 0;
    }

    .add_comment {
      margin-top: 24px;

      & + hr {
        display: none;
      }
    }
    .comment {
      .ava {
        width: 40px;
        height: 40px;

        .comment_content {
          width: calc(100% - 40px);
        }
      }
    }
  }
}
