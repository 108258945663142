body,
p,
form {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 16px;
}
