.detail-activity {
  &__content {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 30px;
    margin-right: 30px;
  }

  &__cards-about {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }

  &__card {
    padding: 16px;
    position: relative;
    border-radius: 8px;
    background-color: #f2f6fa;
    height: 100px;
    border: 0;

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      width: 50px;
      height: 50px;

      img {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      position: absolute;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.15px;
      color: #4167bb;
      padding: 16px 16px 16px 66px;
      top: 50%;
      transform: translateY(-50%);

      span {
        font-size: 20px;
        display: block;

        + span {
          margin-top: 4px;
          font-size: 14px;
        }
      }
    }
  }

  &__text-goal {
    width: 60%;
  }

  &__competences-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__competence-track {
    margin-bottom: 20px;
  }
}
