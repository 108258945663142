.my-works {
  .publications {
    margin: 0;
  }

  .slider-publish {
    width: 100%;

    .slick-arrow {
      top: -55px;
    }
  }

  .slider-publish-off {
    .slick-track {
      margin: 0;
    }
  }
}
