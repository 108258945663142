.project-tasks {
  &__card {
    margin: 16px;
  }

  &__students {
    display: flex;
  }

  &__student {
    width: calc(100% / 9);
  }

  .banner {
    background-color: #eee;
  }
}

.tasks_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;

  &.color_bg {
    margin: 0;
    padding: 24px;
    background-color: #f2f6fa;
    border-radius: 8px;
  }
}

.task_card {
  width: 100%;
  height: 152px;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
  background-color: #fff;
  padding: 16px;

  .content {
    position: relative;
    padding: 0;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
      color: #000;

      b {
        font-size: 16px;
        font-weight: 500;
        color: #000;
      }
    }

    .text {
      height: 40px;
      overflow: hidden;

      span {
        opacity: 0.5;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        display: block;
        font-weight: 400;
      }
    }
  }
}

.add_result {
  margin: auto;
  cursor: pointer;

  .icon {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px dashed rgba(0, 0, 0, 0.05);
    margin: 0 auto;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      background-color: #bfbfbf;
      transition: all 0.15s ease-in-out;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      background-color: #bfbfbf;
      transition: all 0.15s ease-in-out;
      width: 24px;
      height: 2px;
    }

    &:after {
      height: 24px;
      width: 2px;
    }
  }

  span {
    font-size: 16px;
    color: #000;
    opacity: 0.25;
    display: block;
    padding: 16px 10px 0;
    text-align: center;
  }

  &:hover {
    .icon {
      border: 2px solid #1588ee;
      transform: rotate(90deg);

      &:after {
        background: #1588ee;
      }

      &:before {
        background: #1588ee;
        width: 20px;
      }

      &:after {
        height: 20px;
      }
    }

    span {
      opacity: 1;
      color: #1588ee;
    }
  }
}
