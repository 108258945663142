.tippy-popper {
  .tippy-tooltip {
    padding: 13px 16px;
    border-radius: 10px;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1) !important;

    .arrow-regular {
      left: calc(50% - 7px) !important;
      margin: 0;
    }

    .tippy-tooltip-content {
      display: grid;
      text-align: left;

      span {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
