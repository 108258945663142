.banner {
  &_challenge {
    width: 70%;
    height: 800px;
  }

  .btn_block_bg {
    .activity_label {
      min-width: 120px;
      max-width: 135px;
      opacity: 1;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: #fff;
      padding: 0 16px;
      border-radius: 12px 12px 12px 12px;
      background-color: #7563c7;
      text-align: center;
    }

    div span.count {
      text-align: left;
      margin-bottom: -5px;
      margin-top: 5px;
    }

    .activity_label {
      &.bg_participant {
        background-color: #00cd6a;
      }

      &.bg_orange {
        background-color: #ff800b;
      }

      &.bg_closed {
        background-color: #bfbfbf;
      }

      &.bg_done {
        background-color: #2ed0d3;
      }
    }
  }
}
