.left-bar {
  padding: 0;
  width: 320px;

  @media (max-width: 1349px) {
    width: 260px;
  }

  &__page-name {
    padding-bottom: 32px;

    span {
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      color: #000;
    }
  }

  &__back {
    margin-bottom: 12px;

    a {
      padding-left: 24px;
      background: url(../../img/icons/arrow_left.svg) no-repeat 0;
      display: inline-block;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.25);
      line-height: 24px;
      font-weight: 400;
      transition: all 0.15s ease-in-out;

      :hover {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
