.notifications_page .notification {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  position: relative;
  padding: 12px 24px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #fff;
    box-shadow: 0 8px 24px 0 rgba(0, 72, 135, 0.1);
  }

  .info {
    width: 100%;

    > * + * {
      margin-top: 4px;
    }

    .status span {
      display: block;
      opacity: 0.5;
      font-size: 16px;
      line-height: 1.5;
      color: #000;
    }

    .text {
      font-size: 16px;
      line-height: 1.5;
      color: #000;
      word-break: break-word;
    }

    .msg {
      padding: 8px 16px;
      background-color: rgba(117, 99, 199, 0.25);
      display: inline-block;
      border-radius: 20px 20px 20px 4px;
      margin: 8px 0 4px;

      p {
        margin: 0;

        + p {
          margin-top: 8px;
        }
      }
    }

    .btns_block {
      margin: 8px 0 4px;

      > * + * {
        margin-left: 16px;
      }
    }

    .date span {
      display: block;
      opacity: 0.25;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: normal;
      color: #000;
    }
  }

  .ava {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    + .info {
      width: calc(100% - 56px);
      padding-left: 16px;
    }
  }
}

.page_notifications {
  .pagination {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}
