.blocked-user {
  display: block;

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }

  &__button {
    margin: 0 15px;
    max-width: 230px;
  }
}
