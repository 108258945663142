.content-modal {
  padding: 0 24px 24px 24px;
  display: flex;
  flex-wrap: wrap;

  &_space-between {
    justify-content: space-between;
  }

  &__card {
    margin-bottom: 16px;
  }

  &__text {
    width: 100%;
    margin-bottom: 24px
  }
}
