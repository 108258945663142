.toggle-buttons {
  display: flex;
  margin-left: 40px;
  display: -ms-flexbox;

  &__button {
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    display: block;
    padding: 0 15px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.25);
    user-select: none;

    &:hover:not(.toggle-buttons__button_active) {
      color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }
  }

  &__button_active {
    background-color: #1588ee;
    color: #fff;
    opacity: 1;
    cursor: default;
  }
}
