.table-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 24px 0 rgb(0, 72, 135, 0.1);
  background-color: #fff;
  color: #000;

  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;

  &__row {
    display: grid;
    grid-template-columns: 40% 15% 15% 15% 15%;
    grid-template-rows: auto;

    &:hover:not(:first-child) {
      background: rgba(65, 103, 187, 0.05);
    }
  }

  &__row_head {
    background-color: #4167bb;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__row_add-new {
    background: rgba(255, 197, 0, 0.15);
  }

  &__cell {
    padding: 16px 15px 16px 24px;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #f2f6fa;
    font-weight: normal;
  }

  &__cell_name {
    color: #1588ee !important;
    font-weight: bold;
  }

  &__link-activities {
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #0056b3 !important;
    }
  }

  &__cell_group-activities {
    font-weight: normal;
    margin: 0;

    .table-grid__name-item-activities {
      &:nth-child(-n + 4) {
        display: block;
      }
    }
  }

  &__activities {
    overflow: hidden;
    height: 60px;
  }

  &__activities_opened {
    animation: listShow 1s ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  &__activities_closed {
    animation: listClose 1s ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &__series {
    color: #bfbfbf;
    margin: 4px 0 12px 0;
  }

  &__name-subscription {
    color: #000;
    margin: 0 0 4px 0;
  }

  &__name-qualification {
    font-weight: bold;
    margin: 0 0 4px 0;
  }

  &__name-item-activities {
    margin: 8px 0;

    .table-grid__link-activities {
      font-size: 14px;
      font-weight: normal;
    }
  }

  &__show-in-full {
    color: #bfbfbf;
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: #bfbfbf;
    cursor: pointer;
    user-select: none;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: #1588ee;
      border-color: #1588ee;
    }
  }
}

:root {
  --my-start-height: 60px;
  --my-end-height: 60px;
}

@keyframes listShow {
  0% {
    height: var(--my-start-height);
  }
  100% {
    height: var(--my-end-height);
  }
}

@keyframes listClose {
  0% {
    height: var(--my-end-height);
  }
  100% {
    height: var(--my-start-height);
  }
}
