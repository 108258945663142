.reg-agreement {
  height: 90px;

  a {
    font-size: 14px;
  }

  .input-text__notification {
    &_check {
      top: 85px;
    }
  }
}

.reg-success {
  padding: 0 15px;
}
