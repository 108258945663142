.subscription {
  background: #f2f6fa;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #000;
  display: flex;
  justify-content: flex-end;
  padding: 40px 80px 16px;
  border-radius: 8px;
  position: relative;
  height: 244px;

  &:before {
    content: '';
    background: url(../../img/lock.png);
    width: 479px;
    height: 244px;
    position: absolute;
    bottom: 24px;
    left: 5%;
  }

  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__caption {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  &__text {
    margin: 0;
  }

  &__key {
    display: flex;
    justify-content: space-between;
  }

  &__key_activated {
    display: none;
  }

  &__input {
    width: 100%;
    margin-right: 24px;
  }
}
