.button {
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  display: inline-block;
  cursor: pointer;
  border-radius: 22px;
  text-align: center;
  background: #1588ee;
  color: #fff;
  line-height: 1.43;
  user-select: none;
  height: 44px;

  &_border {
    border: 1px solid #1588ee;
    color: #1588ee !important;
    background: #fff;

    &:hover {
      background: #1588ee;
      color: #fff !important;
    }
  }

  &_blue {
    &:hover {
      opacity: 0.8;
    }
  }

  &_big {
    display: block;
    width: 100%;
  }

  &_add {
    padding: 6px 16px;
    height: 32px;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      padding-left: 20px;
      margin-right: 8px;
      background: url(../../img/plus.svg) center no-repeat;
    }
  }

  &:disabled {
    border-color: #fff;
    color: #bfbfbf !important;
    pointer-events: none;
    background: inherit;
  }
}
