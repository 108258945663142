.nav.nav-tabs {
  border-bottom: 0;
  margin: 0 -8px 24px;
  list-style: none;
  padding: 0;
  display: flex;

  .nav-item {
    margin: 0 8px;

    .nav-link {
      border: 0;
      display: block;
      padding: 9px 15px;
      border-radius: 16px;
      cursor: pointer;
      line-height: 1;
      letter-spacing: 0.25px;
      font-size: 14px;
      opacity: 0.25;
      color: #000;
      font-weight: 700;

      &:hover {
        opacity: 1;
        background: #f2f6fa;
        color: #1588ee;
      }

      &.active {
        color: #fff !important;
        opacity: 1;
        background: #1588ee !important;
      }
    }
  }
}

.rating-content {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  & + .rating-content {
    border-top: 1px solid #f2f2f2;
  }
  & > div {
    position: relative;
    &:nth-child(1) {
      width: 40%;
      b {
        display: block;
        color: #7563c7;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 2px;
      }
      span {
        display: block;
        color: #000000;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    &:nth-child(2) {
      width: 60%;
      padding-left: 8px;
      position: relative;
      .progress {
        height: 30px;
        width: 100%;
        position: relative;
        border-radius: 0;
        background: #fff;
        span {
          font-size: 14px;
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          line-height: 1;
          color: #ffffff;
          z-index: 1;
          font-weight: bold;
          pointer-events: none;
        }
        & + .progress {
          margin-top: 8px;
          div {
            background: #ffc500;
          }
        }
      }
    }
  }

  &__link {
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
  }
}

.rating {
  .progress {
    &__num-grey {
      opacity: 0.25 !important;
      color: #000 !important;
    }

    &__tooltip {
      width: 100% !important;
    }

    &__indicator {
      cursor: pointer;
      top: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      background: #00cd6a;
      z-index: 0;
      width: 0;
      transition: all 1s;
    }
  }

  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }
}
